import React, { useContext } from "react";
import { useState } from "react";
import { UserContext } from "../../context/UserContext";
import { logIn } from "../../shared/services";
import { useNavigate } from "react-router-dom";
import LoadingSpinner from "../../shared/loadingSpinner";
import { LOGIN_TIMEOUT } from "../../shared/constants";

function LoginPage() {
  const [username, setUsername] = useState("");
  const [pw, setPw] = useState("");
  const [, setToken] = useContext(UserContext);
  const [errorMessage, setErrorMessage] = useState("");
  const [loading, setLoading] = useState(false);

  const navigate = useNavigate();

  const submitLogin = async () => {
    await logIn({ username: username, password: pw })
      .then((response) => {
        setToken(response.token);

        setTimeout(() => {
          setLoading(false);
          if(response.change_password){
            navigate("/reset_password");
          }
          else{
            navigate("/projects");
            window.location.reload();
          }
        }, LOGIN_TIMEOUT);
      })
      .catch((error) => {
        if (error.response.status === 401) {
          setErrorMessage("Hibás felhasználónév, vagy jelszó!");
        }
        else{
          setErrorMessage("Hiba! " + error);
        }

        setLoading(false);
        return;
      });
  };

  async function handleLogIn(e) {
    e.preventDefault();
    setLoading(true);
    submitLogin();
  }

  return (
    <div className="relative flex flex-col justify-center min-h-screen overflow-hidden bg-gray-800">
      <div className="w-1/2 p-6 m-auto bg-white rounded-md shadow-md shadow-orange-500 ring-2 ring-black lg:max-w-xl">
        <div className="flex flex-col">
          <img
            alt=""
            className="h-[40%] w-[40%] mx-auto"
            src={require("../../assets/icons/logo.png")}
          />
        </div>

        {errorMessage !== "" ? (
          <div>
            <p type="label" className="text-red-600">
              {errorMessage}
            </p>
          </div>
        ) : null}

        <form className="mt-6" onSubmit={handleLogIn}>
          <div className="mb-2">
            <label
              for="username"
              className="block text-sm font-semibold text-gray-800"
            >
              Felhasználónév
            </label>
            <input
              type="username"
              onChange={(e) => setUsername(e.target.value)}
              className="block w-full px-4 py-2 mt-2 text-black bg-white border rounded-md focus:border-orange-300 focus:ring-orange-300 focus:outline-none focus:ring focus:ring-opacity-40"
            />
          </div>
          <div className="mb-2">
            <label
              for="password"
              className="block text-sm font-semibold text-black"
            >
              Jelszó
            </label>
            <input
              type="password"
              onChange={(e) => setPw(e.target.value)}
              className="block w-full px-4 py-2 mt-2 text-black bg-white border rounded-md focus:border-orange-300 focus:ring-orange-300 focus:outline-none focus:ring focus:ring-opacity-40"
            />
          </div>

          <div className="mt-6">
            <button
              disabled={loading}
              type="submit"
              className="w-full px-4 py-2 tracking-wide text-white transition-colors duration-200 transform bg-orange-600 rounded-md hover:bg-orange-700 focus:outline-none focus:bg-orange-700"
            >
              {!loading ? "Bejelentkezés" : <LoadingSpinner />}
            </button>
          </div>
        </form>
      </div>
    </div>
  );
}

export default LoginPage;
