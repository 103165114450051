import React from 'react';
import {HashRouter, Routes, Route } from 'react-router-dom';
import { PrivateRoutes } from './PrivateRoute';

import LoginPage from './components/Login/LoginPage';
import Layout from './components/Layout/Layout'
import NotFound from './components/NotFound/NotFound';
import Projects from './components/Projects/Projects';
import Dashboard from './components/Dashboard/Dashboard';
import AddNewProject from './components/AddNewProject/AddNewProject'
import AddNewPerson from './components/AddNewPerson/AddNewPerson'
import AddressBook from './components/AddressBook/AddressBook';
import Finance from './components/Finance/Finance';
import Settings from './components/Settings/Settings';
import ResetPassword from './components/Login/ResetPassword'

function Navigation() {

  return (
      <HashRouter>
        <Routes>
          <Route path="*" element={<NotFound/>}/>
          <Route exact path="/login" element={<LoginPage />}/>

          <Route element={<PrivateRoutes/>}>
            <Route path="reset_password" element={<ResetPassword/>}/>

            <Route path="/" element={<Layout />}>
              <Route index element={<Dashboard/>}/>
              <Route path="projects" element={<Projects/>}/>
              <Route path="add_new_project" element={<AddNewProject/>}/>
              <Route path="address_book" element={<AddressBook/>}/>
              <Route path="add_new_person" element={<AddNewPerson/>}/>
              <Route path="finance" element={<Finance/>}/>
              <Route path="settings" element={<Settings/>}/>
            </Route>
          </Route>
      
        </Routes>
      </HashRouter>
  );
}

export default Navigation;