import React, { useEffect, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";

export default function ProjectStateFilter({
  projectData,
  setFilteredData,
  openEvents,
}) {
  const [open, setOpen] = useState(false);
  const [selected, setSelected] = useState("Állapot");
  const [selectedColor, setSelectedColor] = useState("bg-orange-500");

  const navigate = useNavigate();
  const location = useLocation();

  const options = [
    {
      id: 1,
      name: "Összes",
      color: "bg-gradient-to-r from-blue-500 via-yellow-500 to-green-500",
    },
    { id: 2, name: "Új", color: "bg-blue-500" },
    { id: 3, name: "Folyamatban", color: "bg-yellow-500" },
    { id: 4, name: "Vége", color: "bg-green-500" },
  ];

  function handleFilter(item) {
    let result = [];
    result = projectData.filter((data) => {
      return data["állapot"] === item;
    });
    setFilteredData(result);
  }

  function handleClick(item) {
    if (item.name === "Összes") {
      setSelected("Állapot");
      setSelectedColor("bg-orange-500");
      setFilteredData(projectData);
      updateURL(""); // Clear the query param for 'all'
    } else {
      setSelected(item.name);
      setSelectedColor(item.color);
      handleFilter(item.name);
      updateURL(item.name); // Update URL with selected filter
    }
    setOpen(false);
  }

  function updateURL(filter) {
    const params = new URLSearchParams(location.search);
    if (filter) {
      params.set("állapot", filter); // Set the filter in the URL
    } else {
      params.delete("állapot"); // Remove the filter from the URL if "Összes" is selected
    }
    navigate({ search: params.toString() }); // Use navigate instead of history.push
  }

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const filterFromURL = params.get("állapot");
    if (filterFromURL) {
      const foundOption = options.find(
        (option) => option.name === filterFromURL
      );
      if (foundOption) {
        setSelected(foundOption.name);
        setSelectedColor(foundOption.color);
        if (projectData) {
          handleFilter(foundOption.name);
        }
      }
    }
  }, [location.search, openEvents]);

  return (
    <div>
      <button
        onClick={() => {
          setOpen(!open);
        }}
        className={`${selectedColor} w-36 text-white hover:bg-orange-800 focus:ring-4 focus:outline-none focus:ring-orange-300 font-bold rounded-lg text-sm px-4 py-2.5 text-center inline-flex items-center justify-between`}
        type="button"
      >
        {selected}
        <svg
          className="w-4 h-4 ml-2"
          aria-hidden="true"
          fill="none"
          stroke="currentColor"
          viewBox="0 0 24 24"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="2"
            d="M19 9l-7 7-7-7"
          />
        </svg>
      </button>
      <div
        className={`${
          open ? "" : "hidden"
        } z-10 bg-gray-700 rounded shadow w-60 absolute`}
      >
        <ul className="h-[30%] py-1 overflow-y-auto text-gray-200">
          {options.map((item) => (
            <li
              key={item.id}
              onClick={() => {
                handleClick(item);
              }}
            >
              <div className="flex items-center px-4 py-2 hover:bg-gray-600 hover:text-white">
                <div
                  className={`${item.color} w-8 h-8 rounded-full shadow-md mr-2`}
                />
                {item.name}
              </div>
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
}
