import { useState } from "react";
import { FaCheck, FaPencilAlt } from "react-icons/fa";
import { useQuery } from "react-query";
import { toast } from "react-toastify";
import LoadingSpinner from "../../../shared/loadingSpinner";
import { editDesignTable, getDesignTable } from "../../../shared/services";

const HEADER_STYLE = "w-1/2 font-['Segoe UI'] text-center font-xl font-bold";

export default function ProjectDetailstDesignTable({selectedProject, openEvents}) {

  const [onEdit, setOnEdit] = useState(false)
  const [dataTable, setDataTable] = useState({
    elso:"",
    masodik:"",
    harmadik:"",
    negyedik:"",
    otodik:"",
    hatodik:""
  })

  const {
    data:dataDesignTable,
    isFetching:fetchingDesingTable,
    refetch:refetcDesignTable
  } = useQuery(
    ["designTable", selectedProject["uuid_projekt"]],
    () => getDesignTable(selectedProject["uuid_projekt"]),
    { enabled: openEvents }
  );

  function handleChange(evt) {
    const value = evt.target.value;
    setDataTable({
      ...dataTable,
      [evt.target.name]: value
    });
  }

  async function onClickSaveEdit(){
    await editDesignTable({projectUID:selectedProject["uuid_projekt"], elso:dataTable.elso, masodik:dataTable.masodik, harmadik:dataTable.harmadik, negyedik:dataTable.negyedik, otodik:dataTable.otodik, hatodik:dataTable.hatodik})
    .then(()=>{
      refetcDesignTable()
      setOnEdit(false)
      toast.success("Elmentve!", {toastId: 'success20'})
    })
    .catch((err)=>{
      toast.error("Hiba! \n"+err)
    })

  }

  function onClickEditTable(){
    setDataTable({
      elso:dataDesignTable.elso,
      masodik:dataDesignTable.masodik,
      harmadik:dataDesignTable.harmadik,
      negyedik:dataDesignTable.negyedik,
      otodik:dataDesignTable.otodik,
      hatodik:dataDesignTable.hatodik
    })
    setOnEdit(true)
  }

  return (
    <div>
      <label className="pt-5 pb-5 font-bold text-xl" type="label">
        Tervezői művezetés
      </label>

      {/* TERVEZŐI MŰVEZ */}
      {/* HEADER */}
      <div className="flex flex-row items-center justify-center px-2 py-2 shadow-md sm:rounded-lg bg-gray-400">
        <p className="w-20 text-center"></p>
        <p className={HEADER_STYLE}>Első</p>
        <p className={HEADER_STYLE}>Második</p>
        <p className={HEADER_STYLE}>Harmadik</p>
        <p className={HEADER_STYLE}>Negyedik</p>
        <p className={HEADER_STYLE}>Ötödik</p>
        <p className={HEADER_STYLE}>Hatodik</p>
      </div>

      {/* BODY */}
      {dataDesignTable && !fetchingDesingTable ? 
      <div className="flex flex-col h-40 overflow-y-scroll no-scrollbar">
            <div className="flex flex-row h-10 px-2 py-2 items-center justify-center">
              <div className="flex w-20 items-center justify-cente">
                {onEdit ? 
                    <FaCheck
                      className="w-6 h-6 fill-green-600 cursor-pointer hover:rotate-6 hover:opacity-5"
                      onClick={() => {
                        onClickSaveEdit();
                      }}
                    />
                   : 
                    <FaPencilAlt
                      className="w-6 h-6 cursor-pointer hover:rotate-6 hover:opacity-5 fill-black"
                      onClick={() => {
                        onClickEditTable();
                      }}
                    />
                  }
                </div>


              <div className="flex w-1/2 items-center justify-center">
                {!onEdit ? 
                <p>{dataDesignTable.elso}</p>
                :
                <input
                className="w-full"
                type="text"
                name="elso"
                onChange={handleChange}
                defaultValue ={dataTable.elso}
                />
                }
              </div>

              <div className="flex w-1/2 items-center justify-center">
                {!onEdit ? 
                <p>{dataDesignTable.masodik}</p>
                :
                <input
                className="w-full"
                type="text"
                name="masodik"
                onChange={handleChange}
                defaultValue ={dataTable.masodik}
                />
                }
              </div>

              <div className="flex w-1/2 items-center justify-center">
                {!onEdit ? 
                <p>{dataDesignTable.harmadik}</p>
                :
                <input
                className="w-full"
                type="text"
                name="harmadik"
                onChange={handleChange}
                defaultValue ={dataTable.harmadik}
                />
                }
              </div>
              <div className="flex w-1/2 items-center justify-center">
                {!onEdit ? 
                <p>{dataDesignTable.negyedik}</p>
                :
                <input
                className="w-full"
                type="text"
                name="negyedik"
                onChange={handleChange}
                defaultValue ={dataTable.negyedik}
                />
                }
              </div>

              <div className="flex w-1/2 items-center justify-center">
                {!onEdit ? 
                <p>{dataDesignTable.otodik}</p>
                :
                <input
                className="w-full"
                type="text"
                name="otodik"
                onChange={handleChange}
                defaultValue ={dataTable.otodik}
                />
                }
              </div>

              <div className="flex w-1/2 items-center justify-center">
                {!onEdit ? 
                <p>{dataDesignTable.hatodik}</p>
                :
                <input
                className="w-full"
                type="text"
                name="hatodik"
                onChange={handleChange}
                defaultValue ={dataTable.hatodik}
                />
                }
              </div>
            </div>
      </div>
      : <LoadingSpinner/>}
      {/* TERVEZŐI MŰVEZ */}
    </div>
  );
}
