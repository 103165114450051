import axios from "axios";
import { API_URL } from "./constants";

const HEADER = {
  "Content-Type": "application/json",
  Authorization: "Bearer " + localStorage.getItem('esteticaToken'),
}


//Login
export async function logIn(user_obj) {
  const { data } = await axios.post(API_URL + "/login", {
    username: user_obj.username,
    password: user_obj.password,
  });
  return data;
}

export async function getCurrentUser(token) {
  let config = {
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + token,
    },
    params: {
      token: token,
    },
  };
  const { data } = await axios.get(API_URL + "/login/current_user", config);
  return { data: data, token: token };
}

export async function resetPassword({password}) {
    let payload = {
      username: localStorage.getItem("username"),
      password: password,
    }

    let config = {
      headers: HEADER,
    };
  
    const { data } = await axios.post(
      API_URL + "/login/reset_password",
      JSON.stringify(payload),
      config
    );
    return data;
}

//Dashboard API calls
export async function getProjectStats() {
  let config = {
    headers: HEADER,
  };

  const { data } = await axios.get(API_URL + "/projects/statistics", config);
  return data;
}

export async function getHitStats() {
  let config = {
    headers: HEADER,
  };

  const { data } = await axios.get(
    API_URL + "/hits/rank_hits_by_person",
    config
  );
  return data;
}

export async function getMapMarkers() {
  let config = {
    headers: HEADER,
  };

  const { data } = await axios.get(API_URL + "/projects/map_markers", config);
  return data;
}

export async function getFinanceStats() {
  let config = {
    headers: HEADER,
  };
  const { data } = await axios.get(API_URL + "/finance/statistics", config);
  return data;
}

//Project API calls
export async function getAllProjects() {
  let config = {
    headers: HEADER,
  };

  const { data } = await axios.get(API_URL + "/projects/get_live", config);
  return data;
}

export async function addNewProject({project}) {
  let payload = {project:project};

  let config = {
    headers: HEADER,
  };

  const { data } = await axios.post(
    API_URL + "/projects/add_new",
    JSON.stringify(payload),
    config
  );
  return data;
}

export async function editProject({project}) {
  let payload = {
    project:project
  };

  let config = {
    headers: HEADER,
  };

  const { data } = await axios.post(
    API_URL + "/projects/edit",
    JSON.stringify(payload),
    config
  );
  return data;
}

export async function editCheckBox({projectUID,colName,value}) {
  let payload = {
    projectUID:projectUID,
    colName: colName,
    value: value,
  };

  let config = {
    headers: HEADER,
  };

  const { data } = await axios.post(
    API_URL + "/projects/edit_checkbox",
    JSON.stringify(payload),
    config
  );
  return data;
}

export async function archiveProject({projectUID}) {
  let payload = {
    projectUID:projectUID
    };

  let config = {
    headers: HEADER,
  };

  const { data } = await axios.post(
    API_URL + "/projects/archive",
    JSON.stringify(payload),
    config
  );
  return data;
}

//Finance
export async function getAllFinance() {
  let config = {
    headers: HEADER,
  };

  const { data } = await axios.get(API_URL + "/finance/get_all", config);
  return data;
}

export async function getFinance(projectID) {
  let config = {
    headers: HEADER,
    params: {
      projectID: projectID,
    },
  };

  try {
    const { data } = await axios.get(API_URL + "/finance/get_one", config);
    return data;  
  } catch (error) {
    console.log("No finance selection")
  }
}

export async function addNewFinance({ projectUID,totalCost, currency }) {
  let payload = {
    projectUID:projectUID,
    totalCost: totalCost,
    currency: currency,
  };

  let config = {
    headers: HEADER,
  };

  const { data } = await axios.post(
    API_URL + "/finance/add_new",
    JSON.stringify(payload),
    config
  );
  return data;
}

export async function addNewPaymentFinance({ projectUID,isPaid, totalCost, currency,isAdvance, value, date }) {
  let payload = {
    projectUID:projectUID,
    isAdvance:isAdvance,
    value:value,
    date:date
  };

  let config = {
    headers: HEADER,
  };

  const { data } = await axios.post(
    API_URL + "/finance/new_payment",
    JSON.stringify(payload),
    config
  );
  return data;
}

export async function editFinance({ projectUID,paymentID, isPaid, totalCost, currency,isAdvance, value, date, szakag }) {
  let payload = {
    projectUID:projectUID,
    paymentID:paymentID,
    isPaid:isPaid,
    totalCost: totalCost,
    currency: currency,
    isAdvance:isAdvance,
    value:value,
    date:date,
    szakag:szakag
  };

  let config = {
    headers: HEADER,
  };

  const { data } = await axios.post(
    API_URL + "/finance/edit_payment",
    JSON.stringify(payload),
    config
  );
  return data;
}

export async function deleteFinance({ projectUID, paymentID,isAdvance }) {
  let payload = {
    projectUID:projectUID,
    paymentID:paymentID,
    isAdvance: isAdvance
  };

  let config = {
    headers: HEADER,
  };

  const { data } = await axios.post(
    API_URL + "/finance/delete_payment",
    JSON.stringify(payload),
    config
  );
  return data;
}

//Address book API calls
export async function getAllPersons() {
  let config = {
    headers: HEADER,
  };

  const { data } = await axios.get(API_URL + "/addressBook", config);
  return data;
}

export async function getAllPersonsGrouped() {
  let config = {
    headers: HEADER,
  };

  const { data } = await axios.get(API_URL + "/addressBook/grouped", config);
  return data;
}

export async function addNewPerson({ name, phone, email, szakag }) {
  let payload = {
    name: name,
    phone: phone,
    email: email,
    szakag: szakag,
  };

  let config = {
    headers: HEADER,
  };

  const { data } = await axios.post(
    API_URL + "/addressBook/add_new",
    JSON.stringify(payload),
    config
  );
  return data;
}

export async function savePersonData({id, name, phone, email,szakag, isDelete}) {
  let payload = {
    id:id,
    name: name,
    phone:phone,
    email:email,
    szakag:szakag,
    isDelete:isDelete
  };

  let config = {
    headers: HEADER,
  };

  const { data } = await axios.post(
    API_URL + "/addressBook/edit_person",
    JSON.stringify(payload),
    config
  );
  return data;
}

//Events API calls
export async function getLatestAllEvents() {
  let config = {
    headers: HEADER,
  };

  const { data } = await axios.get(API_URL + "/events/latest", config);
  return data;
}


export async function getNote(projectUUID) {
  let config = {
    headers: HEADER,
    params: {
      projectUUID: projectUUID,
    },
  };

  const { data } = await axios.get(API_URL + "/events/get_note", config);
  return data;
}

export async function saveNote({projectUID,note}) {
  let config = {
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + localStorage.getItem("esteticaToken"),
    }
  };

  let payload = {
    projectUID: projectUID,
    note:note,
  }

  const { data } = await axios.post(
    API_URL + "/events/save_note",
    JSON.stringify(payload),
    config
  );
  return data;
}

export async function getEvents(projectUUID) {
  let config = {
    headers: HEADER,
    params: {
      projectUUID: projectUUID,
    },
  };

  const { data } = await axios.get(API_URL + "/events/get_events", config);
  return data;
}

export async function addNewEventToProject({
  projectUID,
  eventName,
  startDate,
  isOut,
  user
}) {
  let payload = {
    projectUID: projectUID,
    eventName: eventName,
    startDate: startDate,
    isOut: isOut,
    user:user
  };

  let config = {
    headers: HEADER,
  };

  const { data } = await axios.post(
    API_URL + "/events/new_event",
    JSON.stringify(payload),
    config
  );
  return data;
}

export async function editEvent({
  eventID,
  eventName,
  startDate,
  endDate,
  isOut
}) {
  let payload = {
    eventID: eventID,
    eventName: eventName,
    startDate: startDate,
    endDate: endDate,
    isOut: isOut,
  };

  let config = {
    headers: HEADER,
  };

  const { data } = await axios.post(
    API_URL + "/events/edit_event",
    JSON.stringify(payload),
    config
  );
  return data;
}

export async function deleteEvent({
  eventID,
  isOut
}) {
  let payload = {
    eventID: eventID,
    isOut:isOut
  };

  let config = {
    headers: HEADER,
  };

  const { data } = await axios.post(
    API_URL + "/events/delete_event",
    JSON.stringify(payload),
    config
  );
  return data;
}

export async function moveEvent({eventsArray, direction}) {
  let payload = {
    eventsArray: eventsArray,
    direction:direction
  };

  let config = {
    headers: HEADER,
  };

  const { data } = await axios.post(
    API_URL + "/events/move_event",
    JSON.stringify(payload),
    config
  );
  return data;
}

//Design Table API calls
export async function getDesignTable(projectUID) {
  let config = {
    headers: HEADER,
    params: {
      projectUID: projectUID,
    },
  };

  const { data } = await axios.get(API_URL + "/designTable", config);
  return data;
}

export async function editDesignTable({projectUID, elso, masodik,harmadik,negyedik,otodik,hatodik}) {
  let payload = {
    projectUID: projectUID,
    elso:elso,
    masodik:masodik,
    harmadik:harmadik,
    negyedik:negyedik,
    otodik:otodik,
    hatodik:hatodik
  };

  let config = {
    headers: HEADER,
  };

  const { data } = await axios.post(
    API_URL + "/designTable/save_edit",
    JSON.stringify(payload),
    config
  );
  return data;
}

//Hit person API calls
export async function getAllHit() {
  let config = {
    headers: HEADER,
  };

  const { data } = await axios.get(API_URL + "/hits", config);
  return data;
}

export async function getHitColoring() {
  let config = {
    headers: HEADER,
  };

  const { data } = await axios.get(API_URL + "/hits/hit_coloring", config);
  return data;
}

export async function getHitPersonData(projectUID, column, name) {
  let config = {
    headers: HEADER,
    params: {
      projectUID: projectUID,
      column:column,
      name:name
    },
  };
  const { data } = await axios.get(API_URL + "/hits/hit_person_data", config);
  return data;
}


export async function deleteHit({uid, index}) {
  let payload = {
    uid: uid,
    index:index,
  };

  let config = {
    headers: HEADER,
  };

  const { data } = await axios.post(
    API_URL + "/hits/delete_hit",
    JSON.stringify(payload),
    config
  );
  return data;
}

export async function addHit({endHit, projectUID, name, column}) {
  let payload = {
    endHit: endHit,
    projectUID:projectUID,
    name:name,
    column:column
  };

  let config = {
    headers: HEADER,
  };

  const { data } = await axios.post(
    API_URL + "/hits/hit_person",
    JSON.stringify(payload),
    config
  );
  return data;
}


//Users API calls
export async function getAllUsers() {
  let config = {
    headers: HEADER,
  };

  const { data } = await axios.get(API_URL + "/users/getAll", config);
  return data;
}

export async function changePassword({username}) {
  let config = {
    headers: HEADER,
  };

  let payload = {
    username: username,
  };

  const { data } = await axios.post(API_URL + "/users/change_password", JSON.stringify(payload),config);
  return data;
}


//Settings API calls
export async function getAllColumns() {
  let config = {
    headers: HEADER,
  };

  const { data } = await axios.get(API_URL + "/columns/get_all", config);
  return data;
}

export async function addNewColumn({col_name, type}) {
  let config = {
    headers: HEADER,
  };

  let payload = {
    col_name: col_name,
    type: type,
    visible: true
  };

  const { data } = await axios.post(API_URL + "/columns/add_new_column", JSON.stringify(payload),config);
  return data;
}

export async function changeVisibility({col_id, visible}) {
  let config = {
    headers: HEADER,
  };

  let payload = {
    col_id: col_id,
    visible: visible
  };

  const { data } = await axios.post(API_URL + "/columns/change_visibility", JSON.stringify(payload),config);
  return data;
}

export async function saveNewOrder({table}) {
  let config = {
    headers: HEADER,
  };

  let payload = {
    table: table,
  };

  const { data } = await axios.post(API_URL + "/columns/save_new_order", JSON.stringify(payload),config);
  return data;
}




//Configs
export async function getProfessions() {
  let config = {
    headers: HEADER,
  };

  const { data } = await axios.get(API_URL + "/configs/get_professions", config);
  return data;
}