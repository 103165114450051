import React from 'react'
import { useState } from 'react';
import { useQuery } from 'react-query';
import { toast } from 'react-toastify';
import DropDown from '../../../shared/DropDown';
import { archiveProject, getAllColumns, getAllPersonsGrouped } from '../../../shared/services';

const STATES = ["Új", "Folyamatban", "Vége"];


export default function ProjectDetailsEdit({selectedProject,setSelectedProject, setOpenEvents}) {
    const { isFetching: loadingPersonsGrouped, data: dataPersonsGrouped } = useQuery(
        "persons_grouped",
        getAllPersonsGrouped
    );

    const { isFetching: loadingColumns, data: columns } = useQuery(
        "columns",
        getAllColumns
    );


    const [stateOpen, setStateOpen] = useState(false);
    const [selectedState, setSelectedState] = useState(selectedProject['állapot']);

    // Create an array to hold the states and setters for each Dropdown
    const [inputValues, setInputValues] = useState([]);
    const [dropdownStates, setDropdownStates] = useState([]);
    const [dropdownItems, setDropdownItems] = useState([]);

    // Function to handle input changes
    const handleChange = (event, index) => {
        const { name, value } = event.target;
        
        const newInputValues = [...inputValues];
        newInputValues[index] = { ...newInputValues[index], [name]: value };
        
        setInputValues(newInputValues);

        setSelectedProject((prevProject) => ({
            ...prevProject,
            [name]: value,
        }));

    };

    const handleStateChange= (newState) => {
      setSelectedProject((prevProject) => ({
          ...prevProject,
          "állapot": newState,
      }));
    
      setSelectedState(newState)
  };

    const handleDropdownChange = (index, newItem) => {
        setSelectedProject((prevProject) => ({
            ...prevProject,
            [columns[index].col_name]: newItem,
        }));

        const newDropdownItems = [...dropdownItems];
        newDropdownItems[index] = newItem;
        setDropdownItems(newDropdownItems);
    };

    // Function to toggle the dropdown state
    const toggleDropdown = (index) => {
        const newDropdownStates = [...dropdownStates];
        newDropdownStates[index] = !newDropdownStates[index];
        setDropdownStates(newDropdownStates);
    };
    


    async function onClickArchive(){    
        var answer = window.confirm("Biztosan archiválni szeretnéd ezt a projektet?");
        if (answer) {
          await archiveProject({projectUID:selectedProject["uuid_projekt"]})
          .then(()=>{
            toast.warning("Archiválva!");
            setOpenEvents(false)
          })
          .catch((err) => {
            toast.error("Hiba!\n" + err);
          });
        }
        else{
          return
        }
      }
    


  if (!loadingColumns && !loadingPersonsGrouped) {
  return (
    <div className="flex flex-col gap-5 w-full h-full">
        <div className="w-[20%]">
        <DropDown
            title="Állapot"
            array={STATES}

            state={stateOpen}
            setState={setStateOpen}
            item={selectedState}
            setItem={(newState) => handleStateChange(newState)}

        />
        </div>

        <p className='font-bold text-lg'>Általános adatok</p>

        <div className="flex flex-wrap gap-5 w-full h-full">
                {columns.map((col, colIndex) => {
                    if (col.type === "string" && col.col_name !== "állapot") {
                        return(                        
                            <div key={colIndex} className="flex flex-col w-[20%]">
                                <label type="label">{col.display_name}</label>
                                    <input
                                    name={col.col_name}
                                    onChange={handleChange}
                                    defaultValue={selectedProject[col.col_name]}
                                    type="text"
                                    />
                            </div>
                        )
                    }
                })}
            </div>

            <p className='font-bold text-lg'>Szakágak</p>
            
            <div className="flex flex-wrap gap-5 w-full h-full">
                {columns.map((col, colIndex) => {       
                if (col.type === "szakag") {
                        return(                        
                            <div key={colIndex} className="flex flex-col w-[20%]">
                                <DropDown
                                array={dataPersonsGrouped[col.display_name]}
                                title={col.display_name}
                                state={dropdownStates[colIndex] || false}
                                setState={() => toggleDropdown(colIndex)}
                                item={selectedProject[col.col_name]}
                                setItem={(newItem) => handleDropdownChange(colIndex, newItem)}
                                />
                            </div>
                        )
                    }
                })}
            </div>

            <div className="flex w-full items-center justify-center mt-10">
                <button 
                onClick={()=>{onClickArchive()}} 
                type="button"
                className="bg-red-600"
                >
                    Projekt archiválása
                </button>
            </div>

    </div>
  )
    }
}