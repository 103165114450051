import React from "react";
import { useQuery } from "react-query";
import { formatNumber } from "../../functions/helperFunctions";
import LoadingSpinner from "../../shared/loadingSpinner";
import { getFinanceStats } from "../../shared/services";

export default function Finance() {
  const {
    data: financeData,
    isFetching: financeLoading,
  } = useQuery("finance_stats", getFinanceStats);

  return (
    <div className="flex flex-col w-full h-3/5 rounded-md shadow-md bg-white items-center justify-center my-4">
      {!financeLoading && financeData ? (
        <>
          <div className="flex items-center p-2 h-14 w-full border-b-2">
            <h1 className="font-['Segoe UI'] font-bold text-lg">Pénzügyek</h1>
          </div>

          <div className="flex flex-row h-[90%] w-full justify-evenly">
            <div className="flex flex-col flex-auto justify-center items-center m-4 gap-4">
              <div className="flex flex-col gap-2 w-[45%] h-[35%] items-center justify-center rounded-md bg-slate-100 shadow-md">
                <h1 className="font-['Segoe UI'] text-xl">Összes bevétel</h1>
                <span className="font-['Segoe UI'] text-2xl font-bold">
                  {financeData.sum_incoming_money.huf} Ft
                </span>
                <span className="font-['Segoe UI'] text-2xl font-bold">
                  {financeData.sum_incoming_money.eur} €
                </span>
              </div>

              <div className="flex flex-col gap-2 w-[45%] h-[35%] items-center justify-center rounded-md bg-slate-100 shadow-md">
                <h1 className="font-['Segoe UI'] text-xl">
                  Beérkező bevételek
                </h1>
                <span className="font-['Segoe UI'] text-2xl font-bold">
                  {financeData.sum_awaiting_money.huf} Ft
                </span>
                <span className="font-['Segoe UI'] text-2xl font-bold">
                  {financeData.sum_awaiting_money.eur} €
                </span>
              </div>
            </div>

            <div className="flex flex-col flex-auto justify-center items-center m-4 gap-4">
              <span className="font-['Segoe UI'] text-center text-xl">
                Legtöbb bevételt hozó projektek
              </span>

              <div className="flex h-[70%] overflow-x-auto no-scrollbar shadow-md">
                <table className="dark:text-gray-400">
                  <thead className="text-xs text-gray-700 uppercase bg-slate-100 dark:bg-gray-700 dark:text-gray-400">
                    <tr>
                      <th className="py-3 px-6 text-center">Név</th>
                      <th className="py-3 px-6 text-center">Összeg (Ft)</th>
                      <th className="py-3 px-6 text-center">Kifizetve</th>
                    </tr>
                  </thead>
                  <tbody>
                    {financeData.rank_project_incomes.map((item, index) => (
                      <tr
                        key={index}
                        className="bg-white border-b dark:bg-gray-800 dark:border-gray-700"
                      >
                        <td className="py-4 px-6 text-center">{item.name}</td>
                        <td className="py-4 px-6 text-center">
                          {formatNumber(item.money)}
                        </td>
                        <td className="py-4 px-6 text-center">
                          {item.awaiting === "True" ? "Igen" : "Nem"}
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </>
      ) : (
        <LoadingSpinner />
      )}
    </div>
  );
}
