import React, { useState } from 'react';
import { getMapMarkers } from '../../shared/services';
import LoadingSpinner from '../../shared/loadingSpinner';
import { useQuery } from 'react-query';
import { GoogleMap, LoadScript, Marker, InfoWindow } from '@react-google-maps/api';
import { MAP_STYLE } from './MAP_STYLE';

const HEADER_STYLE="w-1/2 font-['Segoe UI'] text-center font-xl font-bold"

export default function MapProjects(){
    const { isFetching:loading, data:markerData } = useQuery('map_markers', getMapMarkers)
    const [popupInfo,setPopupInfo] = useState(null)

    //GOOGLE MAPS
    const positionv2 = {
        lat: 46.905030,
        lng: 19.693781
      };


    return(
        <div className="flex flex-col w-full h-3/5 rounded-md shadow-sm bg-white items-center justify-center my-2">
			
            <div className="flex items-center p-2 h-14 w-full border-b-2">
                <h1 className="font-['Segoe UI'] font-bold text-lg">Projektek elhelyezkedése</h1>
            </div>

            
            <div className="flex h-full w-3/4 m-4">
            {!loading && markerData? 
            <LoadScript
            googleMapsApiKey={process.env.REACT_APP_GOOGLE_API_KEY}
                >
                <GoogleMap
                mapContainerStyle={{width:'100%', height:'100%'}}
                center={positionv2}
                zoom={10}
                options={{
                    mapTypeControl: false,
                    fullscreenControl: false,
                    streetViewControl: false,
                    styles:MAP_STYLE
                }}
                >
                    {markerData.map((item,index) => {
                        return(
                            <Marker key={index}
                            onClick={() => setPopupInfo(index)}
                            position={{lat:parseFloat(item.lat), lng:parseFloat(item.lng)}}>

                                {popupInfo === index ? (
                                <InfoWindow onCloseClick={() => setPopupInfo(null)}>
                                    <div>
                                        <p type="label" className="text-center font-bold text-xl">{item.city}</p>

                                        
                                            <div  className="flex flex-row items-center justify-center px-2 py-2 shadow-md sm:rounded-lg bg-gray-400">
                                            <p className={HEADER_STYLE}>Név</p>
                                            <p className={HEADER_STYLE}>Állapot</p>
                                            <p className={HEADER_STYLE}>Dátum</p>
                                            </div>

                                            <div className="flex flex-col h-40 overflow-y-scroll no-scrollbar">
                                            {item.projects.map((item,index)=>(
                                                <div key={index} className="flex flex-row h-13 px-2 py-2 items-center justify-center border-b-2">
                                                <div className="flex w-1/2 items-center justify-center">
                                                    <p className='text-center'>{item.name}</p>
                                                </div>
                                                <div className="flex w-1/2 items-center justify-center">
                                                    <p className='text-center'>{item.state}</p>
                                                </div>
                                                <div className="flex w-1/2 items-center justify-center">
                                                    <p className='text-center'>{item.date}</p>
                                                </div>
                                                </div>
                                            ))}
                                            </div>
                                    </div>
                                </InfoWindow>
                                ) : null}
                            </Marker>
                        )
                    })}


                </GoogleMap>
                </LoadScript>
                :
                <div className="flex w-full items-center justify-center">
                    <LoadingSpinner/>
                </div>
                }

            </div>

        </div>
    )
}