import React, { createContext, useEffect, useState } from "react";
import { getCurrentUser } from "../shared/services";

export const UserContext = createContext();

export const UserProvider = (props) => {
  const [token, setToken] = useState(localStorage.getItem("esteticaToken"));

  useEffect(() => {
    const fetchUser = async () => {
      await getCurrentUser(token)
      .then((response)=>{
        if(response){
          localStorage.setItem("esteticaToken", token);
          localStorage.setItem("role", response.data.role);
          localStorage.setItem("username", response.data.user);
        }
      })
      .catch((error)=>{
        setToken(null);
        console.log("Error at UserContext.js ",error.message)    
    })
    };

    
    fetchUser();
  }, [token]);

  return (
    <UserContext.Provider value={[token, setToken]}>
      {props.children}
    </UserContext.Provider>
  );
};