export function formatDate(date) {
    var d = new Date(date),
        month = '' + (d.getMonth() + 1),
        day = '' + d.getDate(),
        year = d.getFullYear();

    if (month.length < 2) 
        month = '0' + month;
    if (day.length < 2) 
        day = '0' + day;

    return [year, month, day].join('-');
}

export function formatNumber(num) {
    var n = new Intl.NumberFormat().format(num);
    var res = n.slice(0, 10).concat("").concat(n.slice(10, 12))
    return res
}

export function truncate(str, n){
    return (str.length > n) ? str.slice(0, n-1) + '...' : str;
  };



export function validateDate(dateStr) {
    const REGEX_PATTERN = /^\d{4}-\d{2}-\d{2}$/;

    if (!REGEX_PATTERN.test(dateStr)) {
        return true
    }

    return false
}