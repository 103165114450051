import React from 'react';
import { useQuery } from 'react-query';
import { getLatestAllEvents } from '../../shared/services';

export default function Events(){

    const {data} = useQuery("latest_events", getLatestAllEvents);

    return(
        <div className="flex flex-col w-full h-full rounded-md shadow-lg bg-white my-4">
            <div className="flex items-center p-2 h-14 w-full border-b-2">
                <h1 className="font-['Segoe UI'] font-bold text-lg">Friss események</h1>
            </div>
        
            <div className={`flex justify-center h-full overflow-y-scroll no-scrollbar rounded-md my-4`}> 
                <table className="font-['Segoe UI'] text-sm text-left text-gray-500 dark:text-gray-400">
                    <thead className="text-xs text-gray-700 uppercase dark:bg-gray-700 dark:text-gray-400">
                        <tr>
                        <th className="py-3 px-6">Projekt</th>
                        <th className="py-3 px-6">Esemény</th>
                        <th className="py-3 px-6">Dátum</th>
                        </tr>
                    </thead>
                    <tbody>
                        {data ?
                        <>
                            {data.map(item  =>
                            <tr key={item._id} className="bg-white border-b dark:bg-gray-800 dark:border-gray-700">
                                <td className="py-4 px-6">{item.project_name}</td>
                                <td className="py-4 px-6">{item.esemeny}</td>
                                <td className="py-4 px-6">{item.kezdo_date}</td>
                            </tr>
                            )}
                        </>
                        : null }
                    </tbody>
                </table>
            </div> 
		</div>
    )
}