import React, { useEffect, useState } from "react";
import DropDown from "../../shared/DropDown";
import LoadingSpinner from "../../shared/loadingSpinner";
import { FaPencilAlt, FaCheck, FaTrashAlt } from "react-icons/fa";
import Tippy from "@tippyjs/react";
import { useQuery } from "react-query";
import {
  getAllPersons,
  getProfessions,
  savePersonData,
} from "../../shared/services";
import SearchBarAddressBook from "./SearchBar_AddressBook";
import { toast } from "react-toastify";
import { SZAKAGAK } from "../../shared/constants";

const HEADER_STYLE = "w-1/2 font-['Segoe UI'] text-center font-xl font-bold";

export default function AddressBook() {
  const {
    isFetching: loadingPersons,
    data: dataPersons,
    refetch: refetchDataPersons,
  } = useQuery("addressBook", getAllPersons, { cacheTime: 0 });

  const [filteredData, setFilteredData] = useState([]);

  const [dropDownOpen, setDropDownOpen] = useState(false);
  const [dropDownSelected, setDropDownSelected] = useState(null);
  const [rowId, setRowId] = useState(-1);

  const [clickedRowID, setClickedRowID] = useState(null);
  const [dropDownOpenEdit, setDropDownOpenEdit] = useState(false);
  const [dropDownSelectedEdit, setDropDownSelectedEdit] = useState(null);

  const [personAttrb, setPersonAttrb] = useState({
    id: "",
    name: "",
    email: "",
    phone: "",
  });

  const { isFetching: loadingProfessions, data: professions } = useQuery(
    "professions",
    getProfessions
  );

  useEffect(() => {
    setFilteredData(dataPersons);
  }, [dataPersons]);

  useEffect(() => {
    if (dropDownSelected) {
      if (dropDownSelected === "Összes") {
        setFilteredData(dataPersons);
        return;
      }

      let result = [];
      result = dataPersons.filter((person) => {
        return person["szakág"].search(dropDownSelected) !== -1;
      });
      setFilteredData(result);
    }
  }, [dropDownSelected]);

  function handleChange(evt) {
    const value = evt.target.value;
    setPersonAttrb({
      ...personAttrb,
      [evt.target.name]: value,
    });
  }

  function onClickEdit(person) {
    setClickedRowID(person._id);
    setPersonAttrb({
      id: person["_id"],
      name: person["név"],
      email: person["email"],
      phone: person["telefon"],
    });
    setDropDownSelectedEdit(person["szakág"]);
  }

  async function onClickSaveEdit() {
    await savePersonData({
      id: personAttrb.id,
      name: personAttrb.name,
      phone: personAttrb.phone,
      email: personAttrb.email,
      szakag: dropDownSelectedEdit,
    })
      .then(() => {
        toast.success("Sikeresen elmentve!", { toastId: "success3" });
        refetchDataPersons();
      })
      .catch((err) => {
        toast.error("Hiba!" + err);
      });

    setClickedRowID(null);
    setPersonAttrb({
      id: "",
      name: "",
      email: "",
      phone: "",
    });
    setDropDownSelectedEdit(null);
  }

  async function onClickDelete() {
    await savePersonData({ id: personAttrb.id, isDelete: true })
      .then(() => {
        toast.warn("Sikeresen törölve!", { toastId: "success3" });
        refetchDataPersons();
      })
      .catch((err) => {
        toast.error("Hiba!" + err);
      });
  }

  return (
    <div className="m-4 h-screen">
      <div className="flex flex-row items-center">
        <SearchBarAddressBook
          setFilteredData={setFilteredData}
          adressBookData={dataPersons}
        />

        {!loadingProfessions ? (
          <div className="w-[15%]">
            <DropDown
              dropDownSize={"w-[15%]"}
              array={["Összes"].concat(
                professions.map((item) => item.prof_name)
              )}
              state={dropDownOpen}
              setState={setDropDownOpen}
              item={dropDownSelected}
              setItem={setDropDownSelected}
              placeholder="Szakág kiválasztása"
            />
          </div>
        ) : null}
      </div>

      {/* HEADER */}
      <div className="flex flex-row items-center justify-center px-2 py-2 shadow-md sm:rounded-lg bg-gray-100">
        <p className="w-3/4 font-['Segoe UI'] text-center font-xl font-bold">
          Név
        </p>
        <p className={HEADER_STYLE}>Telefonszám</p>
        <p className={HEADER_STYLE}>Email</p>
        <p className={HEADER_STYLE}>Szakág</p>
        <p className="w-[10%] font-['Segoe UI'] text-center font-xl font-bold"></p>
      </div>
      {/* HEADER */}

      {/* BODY */}
      {!loadingPersons && filteredData ? (
        <div className="flex flex-col h-3/4 overflow-x-scroll no-scrollbar relative">
          {filteredData
            .sort((a, b) => a["név"].localeCompare(b["név"]))
            .map((person) => {
              return (
                <div
                  key={person._id}
                  onMouseEnter={() => {
                    setRowId(person._id);
                  }}
                  onMouseLeave={() => {
                    setRowId(-1);
                  }}
                  className={`${
                    person._id === rowId ? "bg-gray-200 duration-700" : ""
                  }
                flex flex-row h-20 px-2 py-2 items-center justify-center border-b-2`}
                >
                  <div className="flex w-3/4 items-center justify-center">
                    {clickedRowID === person._id ? (
                      <input
                        className="w-[40%]"
                        type="text"
                        name="name"
                        onChange={handleChange}
                        defaultValue={personAttrb.name}
                      />
                    ) : (
                      <p className="text-center">{person["név"]}</p>
                    )}
                  </div>

                  <div className="flex w-1/2 items-center justify-center">
                    {clickedRowID === person._id ? (
                      <input
                        className="w-[50%]"
                        type="text"
                        name="phone"
                        onChange={handleChange}
                        defaultValue={personAttrb.phone}
                      />
                    ) : (
                      <p className="text-center">{person["telefon"]}</p>
                    )}
                  </div>

                  <div className="flex w-1/2 items-center justify-center">
                    {clickedRowID === person._id ? (
                      <input
                        className="w-[70%]"
                        type="text"
                        name="email"
                        onChange={handleChange}
                        defaultValue={personAttrb.email}
                      />
                    ) : (
                      <p className="text-center">{person["email"]}</p>
                    )}
                  </div>

                  <div className="flex w-1/2 items-center justify-center">
                    {clickedRowID === person._id ? (
                      <DropDown
                        array={SZAKAGAK}
                        state={dropDownOpenEdit}
                        setState={setDropDownOpenEdit}
                        item={dropDownSelectedEdit}
                        setItem={setDropDownSelectedEdit}
                      />
                    ) : (
                      <p className="text-center">{person["szakág"]}</p>
                    )}
                  </div>

                  <Tippy content="Szerkesztés" delay={300}>
                    <div className="flex w-[10%] items-center justify-center">
                      {clickedRowID === person._id ? (
                        <div className="flex flex-row gap-2">
                          <FaCheck
                            className="w-6 h-6 fill-green-600 cursor-pointer hover:rotate-6 hover:opacity-5"
                            onClick={() => {
                              onClickSaveEdit();
                            }}
                          />

                          <FaTrashAlt
                            className="w-6 h-6 fill-red-600 cursor-pointer hover:rotate-6 hover:opacity-5"
                            onClick={() => {
                              onClickDelete();
                            }}
                          />
                        </div>
                      ) : person._id === rowId ? (
                        <FaPencilAlt
                          className="w-6 h-6 cursor-pointer hover:rotate-6 hover:opacity-5 fill-black"
                          onClick={() => {
                            onClickEdit(person);
                          }}
                        />
                      ) : null}
                    </div>
                  </Tippy>
                </div>
              );
            })}
        </div>
      ) : (
        <div className="flex w-full h-1/2 items-center justify-center">
          <LoadingSpinner />
        </div>
      )}
      {/* BODY */}
    </div>
  );
}
