import React from 'react'
import { toast } from 'react-toastify'
import { changeVisibility, saveNewOrder } from '../../../shared/services'
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import { FaEquals } from "react-icons/fa"

export default function RenderColumns({columns, refetchColumns}) {

  async function handleDragEnd(e) {
    if (!e.destination) return;

    let tempData = Array.from(columns);
    let [source_data] = tempData.splice(e.source.index, 1);
    tempData.splice(e.destination.index, 0, source_data);

    tempData.forEach((element, index) => {
      tempData[index].order = index
    });

    //Save data to db
    saveNewOrder({table:tempData})
    .then((res)=>{
      toast.success("Elmentve!");
    })
    .catch((err)=>{
      toast.error("Hiba!\n" + err);
    })

    // setColumns(tempData);
    // refetchColumns()
  };


  function getColTypeVisibleName(colType){
    if (colType === "string") {
      return "Szöveg"
    } 
    else if (colType === "date") {
      return "Dátum"
    }
    else if (colType === "szakag") {
      return "Szakág"
    }
    else if (colType === "checkbox") {
      return "Jelölőnégyzet"
    }
  }

  async function  onClickChangeVisibility({col_id, visible}){
    await changeVisibility({col_id:col_id, visible:visible})
    .then(() => {
      toast.success("Elmentve!");
    })
    .catch((err) => {
      toast.error("Hiba!\n" + err);
    });

    refetchColumns()
  }


  return (
    <div className='flex'>
      <DragDropContext onDragEnd={handleDragEnd}>
        <table className="h-full w-full mx-4 font-['Segoe UI'] text-sm text-left text-gray-500 dark:text-gray-400">
            
            <thead className="text-xs text-gray-700 uppercase dark:bg-gray-700 dark:text-gray-400 text-center">
                <tr>
                    <th className="py-3 px-6"></th>
                    <th className="py-3 px-6">Látható</th>
                    <th className="py-3 px-6">Oszlop neve</th>
                    <th className="py-3 px-6">Típus</th>
                    <th className="py-3 px-6">Sorrend</th>
                </tr>
            </thead>

            <Droppable droppableId="droppable-1">
              {(provider) => (
              <tbody
              ref={provider.innerRef}
              {...provider.droppableProps}
              className='text-center'>
                {columns.sort((a, b) => a.order - b.order).map((col,index)=>

                    <Draggable
                    key={col._id}
                    draggableId={col.col_name}
                    index={index}
                    >
                    
                    {(provider) => (
                    <tr {...provider.draggableProps} ref={provider.innerRef} className="bg-white border-b dark:bg-gray-800 dark:border-gray-700">
                      <td className="py-4 px-6" {...provider.dragHandleProps}> <FaEquals className="w-6 h-6"/> </td>
                      <td className="py-4 px-6">
                        <input
                          className="w-5 h-5"
                          checked={col.visible}
                          type="checkbox"
                          onChange={(e) => {onClickChangeVisibility({col_id: col._id, visible:e.target.checked})}}
                        />
                      </td>
                      <td className="py-4 px-6">{col.display_name}</td>
                      <td className="py-4 px-6">{getColTypeVisibleName(col.type)}</td>
                      <td className="py-4 px-6">{col.order}</td>
                    </tr>    
                    )}

                    </Draggable>
                )}
                {provider.placeholder}
              </tbody>
            )}
            </Droppable>
        </table>
        </DragDropContext>
    </div>
  )
}
