import React from "react";
import { Outlet, useLocation } from 'react-router-dom';

import SideBar from "./SideBar";

export default function  Layout(){
    const { pathname } = useLocation()

    return(
    <div className="flex flex-row h-screen w-screen overflow-hidden">
        <SideBar location={pathname}/>
        <div className="flex-1">
            <div className="">{<Outlet/>}</div>
        </div>
    </div>


    )
}