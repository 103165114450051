import Tippy from "@tippyjs/react";
import "tippy.js/dist/tippy.css"; // optional
import React, { useState } from "react";
import { editCheckBox, getAllColumns } from "../../shared/services";
import { toast } from "react-toastify";
import { useQuery } from "react-query";
import { FaWindowMinimize } from "react-icons/fa";
import { truncate } from "../../functions/helperFunctions";

const HEADER_STYLE = "font-['Segoe UI'] text-center font-xl font-bold";
const SMALL_COL_WIDTH = " w-[20%]";
const NORMAL_COL_WIDTH = " w-[50%]";
const WIDE_COL_WIDTH = " w-[75%]";

export default function RenderProjects({
  openEvents,
  setOpenEvents,
  projectData,
  setSelectedProject,
  setOpenBasztatas,
  setColumn,
  checkBoxClicked,
  setCheckBoxClicked,
  refetchProjects,
}) {
  const [rowId, setRowId] = useState(-1);

  const { isFetching: loadingColumns, data: columns } = useQuery(
    "columns",
    getAllColumns
  );

  async function onClickPerson(item, column) {
    setOpenBasztatas(true);
    setSelectedProject(item);
    setColumn(column); // Use lower case column name, becuase in backend we use .upper() to match with column number
  }

  async function onClickCheckBox(projectUID, colName, value) {
    setCheckBoxClicked(!checkBoxClicked);
    await editCheckBox({
      projectUID: projectUID,
      colName: colName,
      value: value,
    })
      .then(() => {
        toast.success("Elmentve!", { toastId: "success25" });
        refetchProjects();
      })
      .catch((err) => {
        toast.error("Hiba!\n" + err);
      });
  }

  if (!loadingColumns) {
    return (
      <div className={`flex flex-col m-4 mb-10 h-screen`}>
        {/* HEADERS */}
        <div className="flex flex-row items-center justify-center gap-2 py-2 shadow-md sm:rounded-lg bg-gray-100 mx-1">
          {columns
            .filter((col) => col.visible)
            .sort((a, b) => a.order - b.order)
            .map((col, index) => {
              if (col.type === "checkbox") {
                return (
                  <p
                    key={index}
                    className={HEADER_STYLE.concat(SMALL_COL_WIDTH)}
                  >
                    {col.display_name}
                  </p>
                );
              } else if (col.type === "string" && col.col_name !== "állapot") {
                return (
                  <p
                    key={index}
                    className={HEADER_STYLE.concat(WIDE_COL_WIDTH)}
                  >
                    {col.display_name}
                  </p>
                );
              } else {
                return (
                  <p
                    key={index}
                    className={HEADER_STYLE.concat(NORMAL_COL_WIDTH)}
                  >
                    {col.display_name}
                  </p>
                );
              }
            })}
        </div>

        {/* BODY */}
        <div className="flex flex-col h-[80%] overflow-auto relative mx-1">
          {projectData
            .sort((a, b) => a["név"].localeCompare(b["név"]))
            .map((item, itemIndex) => (
              <div
                key={itemIndex}
                onMouseEnter={() => {
                  setRowId(item._id);
                }}
                onMouseLeave={() => {
                  setRowId(-1);
                }}
                className={`${
                  item._id === rowId ? "bg-gray-200 duration-700" : ""
                } flex flex-row h-20 gap-2 py-2 items-center justify-center border-b-2`}
              >
                {item._id === rowId ? (
                  <div className="absolute flex left-0 w-[8%] h-[3rem] bg-orange-500 items-center justify-center rounded-r-md cursor-pointer">
                    <div
                      className="flex w-full h-full items-center justify-center text-center font-bold text-md text-white"
                      onClick={() => {
                        setSelectedProject(item);
                        setOpenEvents(!openEvents);
                      }}
                    >
                      Megnyitás
                    </div>
                  </div>
                ) : null}
                {columns
                  .filter((col) => col.visible)
                  .sort((a, b) => a.order - b.order)
                  .map((col, colIndex) => {
                    const colName = col.col_name;

                    return (
                      <div
                        key={colIndex}
                        className={`${
                          col.type === "checkbox"
                            ? SMALL_COL_WIDTH
                            : col.type === "string" &&
                              col.col_name !== "állapot"
                            ? WIDE_COL_WIDTH
                            : NORMAL_COL_WIDTH
                        } flex items-center justify-center`}
                      >
                        {colName === "állapot" ? (
                          <div
                            className={`${
                              item[colName] === "Új"
                                ? "bg-blue-500"
                                : item[colName] === "Folyamatban"
                                ? "bg-yellow-500"
                                : "bg-green-500"
                            } w-8 h-8 rounded-full shadow-md m-auto`}
                          />
                        ) : col.type === "checkbox" ? (
                          <input
                            className="w-5 h-5"
                            checked={Boolean(item[colName])}
                            type="checkbox"
                            onChange={(e) => {
                              onClickCheckBox(
                                item["_id"],
                                colName,
                                e.target.checked
                              );
                            }}
                          />
                        ) : //Professions
                        col.type === "szakag" ? (
                          <>
                            {item[colName] !== "Nincs még" ? (
                              <Tippy content="Basztatás" delay={300}>
                                <button
                                  onClick={() => {
                                    onClickPerson(item, colName);
                                  }}
                                  className={`
                                          ${
                                            item.hits[colName].length < 1 //item.hits.statikus[0].state
                                              ? ""
                                              : !item.hits[colName][0].state //Ongoing == TRUE, finished (show green) == False
                                              ? "bg-green-500"
                                              : item.hits[colName][0].len === 1
                                              ? "bg-red-300"
                                              : item.hits[colName][0].len === 2
                                              ? "bg-red-500"
                                              : item.hits[colName][0].len === 3
                                              ? "bg-red-800"
                                              : "bg-red-700"
                                          }                                 
                                          text-center rounded-md h-full shadow-sm w-[100%] py-1`}
                                >
                                  {/* if string is too long, then we add ... to the end */}
                                  {truncate(item[colName], 20)}
                                </button>
                              </Tippy>
                            ) : (
                              <p className={"text-center rounded-md w-[100%]"}>
                                {
                                  <div className="flex items-center justify-center">
                                    <FaWindowMinimize className="w-6 h-6 fill-gray-300" />
                                  </div>
                                }
                              </p>
                            )}
                          </>
                        ) : (
                          <p
                            className={"text-center rounded-md ".concat(
                              WIDE_COL_WIDTH
                            )}
                          >
                            {item[colName]}
                          </p>
                        )}
                      </div>
                    );
                  })}
              </div>
            ))}
        </div>
      </div>
    );
  }
}
