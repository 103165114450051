import React from 'react'
import { changePassword, getAllUsers } from '../../shared/services'
import { toast } from "react-toastify";
import LoadingSpinner from '../../shared/loadingSpinner';
import { useQuery } from 'react-query';

export default function RenderUsers() {

    const { isFetching: loadingUsers, data: users } = useQuery(
        "users",
        getAllUsers
    );

    function onClickChangePassword({username}){
        changePassword({username: username})
        .then(()=>{toast.success("Felhasználó jelszóváloztatása sikeres!")})
        .catch((err)=>{toast.error(err)})
    }
    
    return(
        <div className="flex flex-col w-full h-full rounded-md shadow-lg bg-white m-4">
            <div className="flex items-center p-2 h-14 w-full border-b-2">
                <h1 className="font-['Segoe UI'] font-bold text-lg">Felhasználók</h1>
            </div>
        
        {loadingUsers ? <LoadingSpinner /> : 
        <div className={`flex justify-center h-full w-full overflow-y-scroll no-scrollbar rounded-md my-4`}> 
            <table className="h-full w-full mx-4 font-['Segoe UI'] text-sm text-left text-gray-500 dark:text-gray-400">
                <thead className="text-xs text-gray-700 uppercase dark:bg-gray-700 dark:text-gray-400 text-center">
                    <tr>
                        <th className="py-3 px-6">Név</th>
                        <th className="py-3 px-6">Role</th>
                        <th className="py-3 px-6">LastLogin</th>
                        <th className="py-3 px-6">Jelszó</th>
                    </tr>
                </thead>
                <tbody className='text-center'>
                    {users ?
                    <>
                        {users.map(user=>
                        <tr key={user._id} className="bg-white border-b dark:bg-gray-800 dark:border-gray-700">
                            <td className="py-4 px-6">{user.username}</td>
                            <td className="py-4 px-6">{user.Role}</td>
                            <td className="py-4 px-6">{user.LastLogin}</td>
                            <td className="py-4 px-6">
                                <button type='button' onClick={()=>{onClickChangePassword({username:user.username})}}>
                                    Küldés változtatásra
                                </button>
                            </td>
                        </tr>
                        )}
                    </>
                    : null }
                </tbody>
            </table>
        </div> 
        }

    </div>
    )
}