import { toast } from "react-toastify";
import { validateDate } from "../../functions/helperFunctions";

export function getLatestSzakagData(data, prof, isAdvance) {
  let filteredEntries;
  if (isAdvance) {
    filteredEntries = data.data.szakag_eloleg.filter(
      (entry) => entry.szakag === prof
    );
  } else {
    filteredEntries = data.data.szakag_vegosszeg.filter(
      (entry) => entry.szakag === prof
    );
  }

  if (filteredEntries.length === 0) {
    return {
      id: "",
      value: "",
      date: "",
    };
  }

  filteredEntries.sort((a, b) => new Date(b.datum) - new Date(a.datum));

  const latestEntry = filteredEntries[0];

  return {
    id: latestEntry.fizetesID,
    value: latestEntry.osszeg,
    date: latestEntry.datum,
  };
}

export const validateFinanceDate = (dateField) => {
  if (dateField !== "" && validateDate(dateField)) {
    toast.error("Hiba!\n" + "A dátum formátuma nem megfelelő!");
    return true;
  }
  return false;
};

export const validateFinanceValue = (valueField, dateField) => {
  if (dateField !== "" && valueField === "") {
    toast.error(
      "Hiba!\n" + "Ha a dátum meg van adva, az összeg nem lehet üres!"
    );
    return true;
  }
  return false;
};
