import React from "react";
import Navigation from "./Navigation";
import { QueryClient, QueryClientProvider } from "react-query";
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false, //automatically requests fresh data in the background if user leaves the app and returns to stale data.
      refetchOnmount: false, //if true, refetch on mount if the data is stale.
      refetchOnReconnect: false, // if true, refetch on reconnect if the data is stale.
      retry: false, //if true, failed queries will retry infinitely.
      staleTime: 5 * 60 * 1000, //the time in milliseconds after data is considered stale. Defaults to 0.
    },
  },
});

function App() {
  return (
    <QueryClientProvider client={queryClient}>
      <Navigation />

      <ToastContainer
        position="top-right"
        autoClose={3000}
        hideProgressBar={false}
        closeOnClick
        rtl={false}
        theme="light"
      />
      
    </QueryClientProvider>
  );
}

export default App;
