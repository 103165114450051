import React, { useEffect, useState } from "react";
import { useQuery } from "react-query";
import LoadingSpinner from "../../shared/loadingSpinner";
import {
  addNewFinance,
  addNewPaymentFinance,
  getAllProjects,
  getFinance,
  editFinance,
  deleteFinance,
  getAllFinance,
} from "../../shared/services";
import SearchBarFinance from "./SearchBarFinance";
import { ImFileEmpty } from "react-icons/im";
import DropDown from "../../shared/DropDown";
import {
  FaPencilAlt,
  FaCheck,
  FaTrashAlt,
  FaSave,
  FaLockOpen,
  FaLock,
} from "react-icons/fa";
import Tippy from "@tippyjs/react";
import { toast } from "react-toastify";
import {
  getLatestSzakagData,
  validateFinanceDate,
  validateFinanceValue,
} from "./functions";

const HEADER_STYLE = "w-1/2 font-['Segoe UI'] text-center font-xl font-bold";

export default function Finance() {
  const [selectedProject, setSelectedProject] = useState(0);
  const [currencySelector, setCurrencySelector] = useState(false);
  const [currency, setCurrency] = useState(null);
  const [remaining, setRemaining] = useState(0);
  const [filteredData, setFilteredData] = useState([]);
  const [selectedRowID, setSelectedRowID] = useState(-1);
  const [isPaid, setIsPaid] = useState(false);
  const [financeAttrb, setFinanceAttrb] = useState({
    fullValue: "",
    advanceValue: "",
    advanceDate: "",

    totalValue: "",
    totalDate: "",

    idGepeszAdvance: "",
    gepeszAdvanceValue: "",
    gepeszAdvanceDate: "",

    idGepeszTotal: "",
    gepeszTotalValue: "",
    gepeszTotalDate: "",

    idElektronikusAdvance: "",
    elektronikusAdvanceValue: "",
    elektronikusAdvanceDate: "",

    idElektronikusTotal: "",
    elektronikusTotalValue: "",
    elektronikusTotalDate: "",
  });

  const { isFetching: loadingProjects, data: dataProjects } = useQuery(
    "projects",
    getAllProjects
  );

  const { data: dataAllFinance } = useQuery("all_finance", getAllFinance);

  const {
    isFetching: loadingFinance,
    data: dataFinance,
    refetch: refetchFinance,
  } = useQuery(
    ["finance", selectedProject["uuid_projekt"]],
    () => getFinance(selectedProject["uuid_projekt"]),
    { enabled: false, keepPreviousData: false }
  );

  useEffect(() => {
    setFilteredData(dataProjects);
  }, [dataProjects]);

  useEffect(() => {
    refetchFinanceData();
  }, [selectedProject]);

  async function refetchFinanceData() {
    refetchFinance()
      .then((result) => {
        let {
          id: idGepeszAdvance,
          date: dateGepeszAdvance,
          value: valueGepeszAdvance,
        } = getLatestSzakagData(result, "gepesz", true);

        let {
          id: idElektronikusAdvance,
          date: dateElektronikusAdvance,
          value: valueElektronikusAdvance,
        } = getLatestSzakagData(result, "elektronikus", true);

        let {
          id: idGepeszTotal,
          date: dateGepeszTotal,
          value: valueGepeszTotal,
        } = getLatestSzakagData(result, "gepesz", false);
        let {
          id: idElektronikusTotal,
          date: dateElektronikusTotal,
          value: valueElektronikusTotal,
        } = getLatestSzakagData(result, "elektronikus", false);

        setCurrency(result.data.currency);
        setRemaining(result.data.remaining);
        setFinanceAttrb({
          ...financeAttrb,
          fullValue: result.data["tervezesiDij"],
          // Gepesz advance values
          idGepeszAdvance: idGepeszAdvance,
          gepeszAdvanceValue: valueGepeszAdvance,
          gepeszAdvanceDate: dateGepeszAdvance,

          // Elektronikus advance values
          idElektronikusAdvance: idElektronikusAdvance,
          elektronikusAdvanceValue: valueElektronikusAdvance,
          elektronikusAdvanceDate: dateElektronikusAdvance,

          // Gepesz total values
          idGepeszTotal: idGepeszTotal,
          gepeszTotalValue: valueGepeszTotal,
          gepeszTotalDate: dateGepeszTotal,

          // Elektronikus total values
          idElektronikusTotal: idElektronikusTotal,
          elektronikusTotalValue: valueElektronikusTotal,
          elektronikusTotalDate: dateElektronikusTotal,
        });
        if (
          result.data.kifizetve === "True" ||
          result.data.kifizetve === true
        ) {
          setIsPaid(true);
        } else {
          setIsPaid(false);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }

  async function onClickProject(project) {
    setSelectedProject(project);
  }

  function handleChange(evt) {
    const value = evt.target.value;
    setFinanceAttrb({
      ...financeAttrb,
      [evt.target.name]: value,
    });
  }

  async function onClickChangeIsPaid() {
    if (dataFinance.kifizetve === "True" || dataFinance.kifizetve === true) {
      await editFinance({
        projectUID: selectedProject["uuid_projekt"],
        isPaid: !isPaid,
      })
        .then(() => {
          refetchFinanceData();
          setIsPaid(!isPaid);
          toast.success("Sikeresen elmentve!", { toastId: "success4" });
        })
        .catch((err) => {
          toast.error("Hiba!\n" + err);
        });
    } else if (dataFinance.remaining > 0) {
      var answer = window.confirm(
        "Még van hátralévő kifizetési összeg! Biztosan át akarod állítani a projektet 'kifizetve' állapotra?"
      );
      if (answer) {
        await editFinance({
          projectUID: selectedProject["uuid_projekt"],
          isPaid: !isPaid,
        })
          .then(() => {
            refetchFinanceData();
            setIsPaid(!isPaid);
            toast.success("Sikeresen elmentve!", { toastId: "success4" });
          })
          .catch((err) => {
            toast.error("Hiba!\n" + err);
          });
      }
    }
  }

  async function editTotalCost(event) {
    if (financeAttrb.fullValue === "") {
      toast.error("Whoopsz! Az összeg mező üres!");
      return;
    }

    if (event.key === "Enter") {
      await editFinance({
        projectUID: selectedProject["uuid_projekt"],
        totalCost: financeAttrb.fullValue,
      })
        .then(() => {
          refetchFinanceData();
          toast.success("Sikeresen elmentve!", { toastId: "success5" });
        })
        .catch((err) => {
          toast.error("Hiba!\n" + err);
        });
    }
  }

  async function saveNewFinance() {
    if (financeAttrb.fullValue === "" || currency === undefined) {
      toast.error("Whoopsz! Valamelyik mező nem lett kitöltve!");
      return;
    }

    await addNewFinance({
      projectUID: selectedProject["uuid_projekt"],
      totalCost: financeAttrb.fullValue,
      currency: currency,
    })
      .then(() => {
        refetchFinanceData();
        toast.success("Sikeresen elmentve!", { toastId: "success6" });
      })
      .catch((err) => {
        toast.error("Hiba!\n" + err);
      });
  }

  async function onClickAddNewPayment(isAdvance) {
    if (
      validateFinanceDate(financeAttrb.advanceDate) ||
      validateFinanceDate(financeAttrb.elektronikusAdvanceDate) ||
      validateFinanceDate(financeAttrb.elektronikusTotalDate) ||
      validateFinanceDate(financeAttrb.gepeszAdvanceDate) ||
      validateFinanceDate(financeAttrb.totalDate) ||
      validateFinanceDate(financeAttrb.gepeszTotalDate)
    ) {
      return;
    }

    if (
      validateFinanceValue(
        financeAttrb.advanceValue,
        financeAttrb.advanceDate
      ) ||
      validateFinanceValue(
        financeAttrb.elektronikusAdvanceValue,
        financeAttrb.elektronikusAdvanceDate
      ) ||
      validateFinanceValue(
        financeAttrb.elektronikusTotalValue,
        financeAttrb.elektronikusTotalDate
      ) ||
      validateFinanceValue(
        financeAttrb.gepeszAdvanceValue,
        financeAttrb.gepeszAdvanceDate
      ) ||
      validateFinanceValue(
        financeAttrb.gepeszTotalValue,
        financeAttrb.gepeszTotalDate
      ) ||
      validateFinanceValue(financeAttrb.totalValue, financeAttrb.totalDate)
    ) {
      return;
    }

    if (financeAttrb.advanceValue === "" || financeAttrb.advanceDate === "") {
      toast.error("Hiba!\n" + "Üres értéket nem lehet elmenteni!");
      return;
    }

    if (isAdvance) {
      await addNewPaymentFinance({
        projectUID: selectedProject["uuid_projekt"],
        isAdvance: isAdvance,
        value: financeAttrb.advanceValue,
        date: financeAttrb.advanceDate,
      })
        .then(() => {
          refetchFinanceData();
          toast.success("Sikeresen elmentve!", { toastId: "success7" });
        })
        .catch((err) => {
          toast.error("Hiba!\n" + err);
        });
    } else {
      await addNewPaymentFinance({
        projectUID: selectedProject["uuid_projekt"],
        isAdvance: isAdvance,
        value: financeAttrb.totalValue,
        date: financeAttrb.totalDate,
      })
        .then(() => {
          refetchFinanceData();
          toast.success("Sikeresen elmentve!", { toastId: "success8" });
        })
        .catch((err) => {
          toast.error("Hiba!\n" + err);
        });
    }

    setSelectedRowID(-1);
  }

  function onClickEditRow({ id, value, date, isAdvance }) {
    setSelectedRowID(id);
    if (isAdvance) {
      setFinanceAttrb({
        ...financeAttrb,
        advanceValue: value,
        advanceDate: date,
      });
    } else {
      setFinanceAttrb({
        ...financeAttrb,
        totalValue: value,
        totalDate: date,
      });
    }
  }

  async function onClickSaveEdit({ id, isAdvance }) {
    if (isAdvance) {
      await editFinance({
        paymentID: id,
        isAdvance: isAdvance,
        value: financeAttrb.advanceValue,
        date: financeAttrb.advanceDate,
      })
        .then(() => {
          refetchFinanceData();
          toast.success("Sikeresen elmentve!", { toastId: "success10" });
          setSelectedRowID(-1);
        })
        .catch((err) => {
          toast.error("Hiba!\n" + err);
          setSelectedRowID(-1);
        });
    } else {
      await editFinance({
        paymentID: id,
        isAdvance: isAdvance,
        value: financeAttrb.totalValue,
        date: financeAttrb.totalDate,
      })
        .then(() => {
          refetchFinanceData();
          toast.success("Sikeresen elmentve!", { toastId: "success11" });
          setSelectedRowID(-1);
        })
        .catch((err) => {
          toast.error("Hiba!\n" + err);
          setSelectedRowID(-1);
        });
    }
  }

  async function onClickDeleteFinance({ projectUID, paymentID, isAdvance }) {
    await deleteFinance({
      projectUID: projectUID,
      paymentID: paymentID,
      isAdvance: isAdvance,
    })
      .then(() => {
        refetchFinanceData();
        toast.success("Sikeresen elmentve!", { toastId: "success12" });
        setSelectedRowID(-1);
      })
      .catch((err) => {
        toast.error("Hiba!\n" + err);
        setSelectedRowID(-1);
      });
  }

  function RenderOverlay() {
    return <div className="absolute w-full h-[85%] top-20 z-10" />;
  }

  async function onClickSaveSzakag({ szakag, isAdvance, value, date }) {
    await editFinance({
      projectUID: selectedProject["uuid_projekt"],
      isAdvance: isAdvance,
      szakag: szakag,
      value: value,
      date: date,
    })
      .then(() => {
        refetchFinanceData();
        toast.success("Sikeresen elmentve!", { toastId: "success10" });
      })
      .catch((err) => {
        toast.error("Hiba!\n" + err);
      });
  }

  return (
    <div className="flex flex-row w-full h-screen m-4">
      <div className="flex w-[20%] h-full justify-center">
        <div className="flex flex-col w-[90%] h-[90%] bg-gray-300 rounded-sm shadow-md">
          <label type="label" className="mt-2 text-center font-bold text-xl">
            Projektek
          </label>
          <SearchBarFinance
            projectData={dataProjects}
            setFilteredData={setFilteredData}
          />

          {!loadingProjects && filteredData && dataAllFinance ? (
            <div className="h-full m-2 overflow-y-scroll no-scrollbar">
              {filteredData
                .sort((a, b) => a["név"].localeCompare(b["név"]))
                .map((item, index) => {
                  return (
                    <div
                      onClick={() => {
                        onClickProject(item);
                      }}
                      key={index}
                      className={`
                        ${
                          item._id === selectedProject._id
                            ? "bg-blue-400 border-1 border-black"
                            : ""
                        }
                        flex w-full mb-4 h-14 bg-slate-200 items-center justify-center rounded-md shadow-sm hover:bg-slate-400 cursor-pointer
                        `}
                    >
                      <p
                        className={`${
                          dataAllFinance.some(
                            (e) =>
                              e._id === item["uuid_projekt"] &&
                              (e.kifizetve === false || e.kifizetve === "False")
                          )
                            ? "text-red-600 font-bold"
                            : dataAllFinance.some(
                                (e) =>
                                  e._id === item["uuid_projekt"] &&
                                  (e.kifizetve === true ||
                                    e.kifizetve === "True")
                              )
                            ? "text-green-600 font-bold"
                            : ""
                        }
                        text-center`}
                      >
                        {item["név"]}
                      </p>
                    </div>
                  );
                })}
            </div>
          ) : (
            <div className="flex w-full h-full items-center justify-center">
              <LoadingSpinner />
            </div>
          )}
        </div>
      </div>

      <div className="w-[78%] relative">
        <div className="flex w-full h-[90%] justify-center bg-slate-200 rounded-sm shadow-md">
          {selectedProject === 0 ? (
            <div className="flex flex-col h-full w-full items-center justify-center">
              <ImFileEmpty className="w-10 h-10" />

              <p className="mt-5 text-2xl font-bolt">
                Nincs projekt kiválasztva
              </p>
            </div>
          ) : (
            <>
              {!loadingFinance && dataFinance ? (
                <div className="flex flex-col w-full h-full m-4 relative">
                  {isPaid ? RenderOverlay() : null}
                  <div className="flex flex-row items-center justify-between m-4">
                    <p className="text-2xl font-bold">
                      Kiválasztva: {selectedProject["név"]}
                    </p>

                    <div>
                      <button
                        onClick={() => {
                          onClickChangeIsPaid();
                        }}
                        type="button"
                        className={`${
                          isPaid ? "bg-green-600" : null
                        } flex flex-row gap-2`}
                      >
                        <p>Kifizetve</p>

                        {isPaid ? (
                          <FaLock className="w-5 h-5 fill-black" />
                        ) : (
                          <FaLockOpen className="w-5 h-5 fill-black" />
                        )}
                      </button>
                    </div>
                  </div>

                  <div className="flex flex-row m-4 items-center justify-center gap-4">
                    <p className="w-[10%] font-bold">Tervezési díj</p>
                    <input
                      name="fullValue"
                      onChange={handleChange}
                      onKeyDown={editTotalCost}
                      className="w-[20%]"
                      placeholder="Összeg"
                      type="text"
                      defaultValue={financeAttrb.fullValue}
                    />

                    <div className="w-[8%]">
                      <DropDown
                        dropDownSize={"w-[5%]"}
                        array={["Ft", "Eur"]}
                        placeholder={"Pénznem"}
                        state={currencySelector}
                        setState={setCurrencySelector}
                        item={currency}
                        setItem={setCurrency}
                      />
                    </div>

                    {remaining && currency ? (
                      <p className="w-[20%] font-bold">
                        Hátravan: {remaining} {currency}
                      </p>
                    ) : (
                      <Tippy content="Mentés" delay={300}>
                        <div
                          onClick={() => {
                            saveNewFinance();
                          }}
                        >
                          <FaSave className="w-6 h-6 fill-green-600 cursor-pointer hover:rotate-6 hover:opacity-5 items-center justify-center" />
                        </div>
                      </Tippy>
                    )}
                  </div>

                  {Object.keys(dataFinance).length !== 0 ? (
                    <>
                      {/* START OF TOP TABLE */}
                      <div className="pl-40">
                        <div className="flex flex-row w-full gap-4">
                          <div className="w-1/2 items-center justify-center">
                            {/* HEADERS */}
                            <div className="flex flex-row items-center justify-center px-2 py-2 shadow-md sm:rounded-lg bg-gray-100">
                              <p className={HEADER_STYLE}>Előleg</p>
                              <p className={HEADER_STYLE}>Dátum</p>
                              <p className="w-[10%]"></p>
                            </div>
                            {/* HEADERS */}

                            {/* BODY */}
                            <div className="flex flex-col relative">
                              {Object.keys(dataFinance).length === 0 ? (
                                <div
                                  className={`flex flex-row h-20 items-center justify-center border-b-2`}
                                >
                                  <div className="flex w-1/2 items-center justify-center">
                                    <input
                                      name="advanceValue"
                                      onChange={handleChange}
                                      className="w-[50%]"
                                      type="text"
                                      placeholder="50000 Ft"
                                    />
                                  </div>

                                  <div className="flex w-1/2 items-center justify-center">
                                    <input
                                      name="advanceDate"
                                      onChange={handleChange}
                                      className="w-[50%]"
                                      type="text"
                                      placeholder="2022-10-12"
                                    />
                                  </div>

                                  <div className="flex w-[10%] items-center justify-center">
                                    <FaCheck
                                      onClick={() => {
                                        onClickAddNewPayment(true);
                                      }}
                                      className="w-6 h-6 fill-green-600 cursor-pointer hover:rotate-6 hover:opacity-5"
                                    />
                                  </div>
                                </div>
                              ) : (
                                <>
                                  {dataFinance.elolegek.map((item) => (
                                    <div
                                      key={item.fizetesID}
                                      className={`flex flex-row h-20 items-center justify-center border-b-2`}
                                    >
                                      <div className="flex w-1/2 items-center justify-center">
                                        {selectedRowID === item.fizetesID ? (
                                          <input
                                            defaultValue={item.osszeg}
                                            className="w-[50%]"
                                            name="advanceValue"
                                            onChange={handleChange}
                                            type="text"
                                            placeholder="50000 Ft"
                                          />
                                        ) : (
                                          <p>{item.osszeg}</p>
                                        )}
                                      </div>

                                      <div className="flex w-1/2 items-center justify-center">
                                        {selectedRowID === item.fizetesID ? (
                                          <input
                                            defaultValue={item.datum}
                                            className="w-[50%]"
                                            name="advanceDate"
                                            onChange={handleChange}
                                            type="text"
                                            placeholder="2022-12-12"
                                          />
                                        ) : (
                                          <p>{item.datum}</p>
                                        )}
                                      </div>

                                      <div className="flex w-[10%] items-center justify-center">
                                        {selectedRowID === item.fizetesID ? (
                                          <div className="flex flex-row gap-2">
                                            <FaTrashAlt
                                              onClick={() => {
                                                onClickDeleteFinance({
                                                  projectUID:
                                                    selectedProject[
                                                      "uuid_projekt"
                                                    ],
                                                  paymentID: item.fizetesID,
                                                  isAdvance: true,
                                                });
                                              }}
                                              className="w-6 h-6 fill-red-600 cursor-pointer hover:rotate-6 hover:opacity-5"
                                            />
                                            <FaCheck
                                              onClick={() => {
                                                onClickSaveEdit({
                                                  id: item.fizetesID,
                                                  isAdvance: true,
                                                });
                                              }}
                                              className="w-6 h-6 fill-green-600 cursor-pointer hover:rotate-6 hover:opacity-5"
                                            />
                                          </div>
                                        ) : (
                                          <FaPencilAlt
                                            onClick={() => {
                                              onClickEditRow({
                                                id: item.fizetesID,
                                                value: item.osszeg,
                                                date: item.datum,
                                                isAdvance: true,
                                              });
                                            }}
                                            className="w-6 h-6 fill-grey-600 cursor-pointer hover:rotate-6 hover:opacity-5"
                                          />
                                        )}
                                      </div>
                                    </div>
                                  ))}
                                  <div
                                    className={`flex flex-row h-20 items-center justify-center border-b-2`}
                                  >
                                    <div className="flex w-1/2 items-center justify-center">
                                      <input
                                        className="w-[50%]"
                                        name="advanceValue"
                                        onChange={handleChange}
                                        type="text"
                                        placeholder="50000 Ft"
                                      />
                                    </div>

                                    <div className="flex w-1/2 items-center justify-center">
                                      <input
                                        className="w-[50%]"
                                        name="advanceDate"
                                        onChange={handleChange}
                                        type="text"
                                        placeholder="2022-10-12"
                                      />
                                    </div>

                                    <div className="flex w-[10%] items-center justify-center">
                                      <FaCheck
                                        onClick={() => {
                                          onClickAddNewPayment(true);
                                        }}
                                        className="w-6 h-6 fill-green-600 cursor-pointer hover:rotate-6 hover:opacity-5"
                                      />
                                    </div>
                                  </div>
                                </>
                              )}
                            </div>
                            {/* BODY */}
                          </div>

                          <div className="w-1/2 items-center justify-center">
                            {/* HEADERS */}
                            <div className="flex flex-row items-center justify-center px-2 py-2 shadow-md sm:rounded-lg bg-gray-100">
                              <p className={HEADER_STYLE}>Végösszeg</p>
                              <p className={HEADER_STYLE}>Dátum</p>
                            </div>
                            {/* HEADERS */}

                            {/* BODY */}
                            <div className="flex flex-col relative">
                              {Object.keys(dataFinance).length === 0 ? (
                                <div
                                  className={`flex flex-row h-20 items-center justify-center border-b-2`}
                                >
                                  <div className="flex w-1/2 items-center justify-center">
                                    <input
                                      name="totalValue"
                                      onChange={handleChange}
                                      className="w-[50%]"
                                      type="text"
                                      placeholder="50000 Ft"
                                    />
                                  </div>

                                  <div className="flex w-1/2 items-center justify-center">
                                    <input
                                      name="totalDate"
                                      onChange={handleChange}
                                      className="w-[50%]"
                                      type="text"
                                      placeholder="2022-10-12"
                                    />
                                  </div>

                                  <div className="flex w-[10%] items-center justify-center">
                                    <FaCheck
                                      onClick={() => {
                                        onClickAddNewPayment(false);
                                      }}
                                      className="w-6 h-6 fill-green-600 cursor-pointer hover:rotate-6 hover:opacity-5"
                                    />
                                  </div>
                                </div>
                              ) : (
                                <>
                                  {dataFinance.vegosszegek.map((item) => (
                                    <div
                                      key={item.fizetesID}
                                      className={`flex flex-row h-20 items-center justify-center border-b-2`}
                                    >
                                      <div className="flex w-1/2 items-center justify-center">
                                        {selectedRowID === item.fizetesID ? (
                                          <input
                                            defaultValue={item.osszeg}
                                            className="w-[50%]"
                                            name="totalValue"
                                            onChange={handleChange}
                                            type="text"
                                            placeholder="50000 Ft"
                                          />
                                        ) : (
                                          <p>{item.osszeg}</p>
                                        )}
                                      </div>

                                      <div className="flex w-1/2 items-center justify-center">
                                        {selectedRowID === item.fizetesID ? (
                                          <input
                                            defaultValue={item.datum}
                                            className="w-[50%]"
                                            name="totalDate"
                                            onChange={handleChange}
                                            type="text"
                                            placeholder="2022-12-12"
                                          />
                                        ) : (
                                          <p>{item.datum}</p>
                                        )}
                                      </div>

                                      <div className="flex w-[10%] items-center justify-center">
                                        {selectedRowID === item.fizetesID ? (
                                          <div className="flex flex-row gap-2">
                                            <FaTrashAlt
                                              onClick={() => {
                                                onClickDeleteFinance({
                                                  projectUID:
                                                    selectedProject[
                                                      "uuid_projekt"
                                                    ],
                                                  paymentID: item.fizetesID,
                                                  isAdvance: false,
                                                });
                                              }}
                                              className="w-6 h-6 fill-red-600 cursor-pointer hover:rotate-6 hover:opacity-5"
                                            />
                                            <FaCheck
                                              onClick={() => {
                                                onClickSaveEdit({
                                                  id: item.fizetesID,
                                                  isAdvance: false,
                                                });
                                              }}
                                              className="w-6 h-6 fill-green-600 cursor-pointer hover:rotate-6 hover:opacity-5"
                                            />
                                          </div>
                                        ) : (
                                          <FaPencilAlt
                                            onClick={() => {
                                              onClickEditRow({
                                                id: item.fizetesID,
                                                value: item.osszeg,
                                                date: item.datum,
                                                isAdvance: false,
                                              });
                                            }}
                                            className="w-6 h-6 fill-grey-600 cursor-pointer hover:rotate-6 hover:opacity-5"
                                          />
                                        )}
                                      </div>
                                    </div>
                                  ))}
                                  <div
                                    className={`flex flex-row h-20 items-center justify-center border-b-2`}
                                  >
                                    <div className="flex w-1/2 items-center justify-center">
                                      <input
                                        className="w-[50%]"
                                        name="totalValue"
                                        onChange={handleChange}
                                        type="text"
                                        placeholder="50000 Ft"
                                      />
                                    </div>

                                    <div className="flex w-1/2 items-center justify-center">
                                      <input
                                        className="w-[50%]"
                                        name="totalDate"
                                        onChange={handleChange}
                                        type="text"
                                        placeholder="2022-10-12"
                                      />
                                    </div>

                                    <div className="flex w-[10%] items-center justify-center">
                                      <FaCheck
                                        onClick={() => {
                                          onClickAddNewPayment(false);
                                        }}
                                        className="w-6 h-6 fill-green-600 cursor-pointer hover:rotate-6 hover:opacity-5"
                                      />
                                    </div>
                                  </div>
                                </>
                              )}
                            </div>
                            {/* BODY */}
                          </div>
                        </div>
                      </div>
                      {/* END OF TOP TABLE */}

                      {/* GÉPÉSZ, ELEKTRONIKUS TABLE */}
                      <div className="flex flex-row w-full h-[20%]">
                        <div className="flex flex-col w-40 h-full justify-center">
                          <div className="flex flex-auto flex-col justify-center">
                            <p>Gépész</p>
                            <p className="font-bold text-md">
                              {selectedProject["gépész"]}
                            </p>
                          </div>

                          <div className="flex flex-auto flex-col justify-center">
                            <p>Elektronikus</p>
                            <p className="font-bold text-md">
                              {selectedProject["elektronikus"]}
                            </p>
                          </div>
                        </div>

                        {/* TABLE */}
                        <div className="flex flex-col flex-auto ">
                          <div className="flex flex-auto flex-row gap-4">
                            <div className="flex w-1/2 items-center justify-center gap-4 relative">
                              <input
                                defaultValue={financeAttrb.gepeszAdvanceValue}
                                name="gepeszAdvanceValue"
                                onChange={handleChange}
                                className="w-[35%]"
                                type="text"
                                placeholder="5000 Ft"
                              />

                              <input
                                defaultValue={financeAttrb.gepeszAdvanceDate}
                                name="gepeszAdvanceDate"
                                onChange={handleChange}
                                className="w-[35%]"
                                type="text"
                                placeholder="2022-12-12"
                              />

                              {financeAttrb.idGepeszAdvance === "" ? (
                                <div className="absolute right-[10px]">
                                  <FaCheck
                                    onClick={() => {
                                      onClickSaveSzakag({
                                        szakag: "gepesz",
                                        isAdvance: true,
                                        value: financeAttrb.gepeszAdvanceValue,
                                        date: financeAttrb.gepeszAdvanceDate,
                                      });
                                    }}
                                    className="w-6 h-6 fill-green-600 cursor-pointer hover:rotate-6 hover:opacity-5"
                                  />
                                </div>
                              ) : (
                                <div className="absolute right-[10px]">
                                  {selectedRowID ===
                                  financeAttrb.idGepeszAdvance ? (
                                    <FaTrashAlt
                                      onClick={() => {
                                        onClickDeleteFinance({
                                          projectUID:
                                            selectedProject["uuid_projekt"],
                                          paymentID:
                                            financeAttrb.idGepeszAdvance,
                                          isAdvance: true,
                                        });
                                      }}
                                      className="w-6 h-6 fill-red-600 cursor-pointer hover:rotate-6 hover:opacity-5"
                                    />
                                  ) : (
                                    <FaPencilAlt
                                      onClick={() => {
                                        onClickEditRow({
                                          id: financeAttrb.idGepeszAdvance,
                                          value:
                                            financeAttrb.gepeszAdvanceValue,
                                          date: financeAttrb.gepeszAdvanceDate,
                                          isAdvance: false,
                                        });
                                      }}
                                      className="w-6 h-6 fill-grey-600 cursor-pointer hover:rotate-6 hover:opacity-5"
                                    />
                                  )}
                                </div>
                              )}
                            </div>
                            <div className="flex w-1/2 items-center justify-center gap-4 relative">
                              <input
                                defaultValue={financeAttrb.gepeszTotalValue}
                                name="gepeszTotalValue"
                                onChange={handleChange}
                                className="w-[35%]"
                                type="text"
                                placeholder="5000 Ft"
                              />

                              <input
                                defaultValue={financeAttrb.gepeszTotalDate}
                                name="gepeszTotalDate"
                                onChange={handleChange}
                                className="w-[35%]"
                                type="text"
                                placeholder="2022-12-12"
                              />

                              {financeAttrb.idGepeszTotal === "" ? (
                                <div className="absolute right-[10px]">
                                  <FaCheck
                                    onClick={() => {
                                      onClickSaveSzakag({
                                        szakag: "gepesz",
                                        isAdvance: false,
                                        value: financeAttrb.gepeszTotalValue,
                                        date: financeAttrb.gepeszTotalDate,
                                      });
                                    }}
                                    className="w-6 h-6 fill-green-600 cursor-pointer hover:rotate-6 hover:opacity-5"
                                  />
                                </div>
                              ) : (
                                <div className="absolute right-[10px]">
                                  {selectedRowID ===
                                  financeAttrb.idGepeszTotal ? (
                                    <FaTrashAlt
                                      onClick={() => {
                                        onClickDeleteFinance({
                                          projectUID:
                                            selectedProject["uuid_projekt"],
                                          paymentID: financeAttrb.idGepeszTotal,
                                          isAdvance: true,
                                        });
                                      }}
                                      className="w-6 h-6 fill-red-600 cursor-pointer hover:rotate-6 hover:opacity-5"
                                    />
                                  ) : (
                                    <FaPencilAlt
                                      onClick={() => {
                                        onClickEditRow({
                                          id: financeAttrb.idGepeszTotal,
                                          value: financeAttrb.gepeszTotalValue,
                                          date: financeAttrb.gepeszTotalDate,
                                          isAdvance: false,
                                        });
                                      }}
                                      className="w-6 h-6 fill-grey-600 cursor-pointer hover:rotate-6 hover:opacity-5"
                                    />
                                  )}
                                </div>
                              )}
                            </div>
                          </div>

                          <div className="flex flex-auto flex-row gap-4">
                            <div className=" flex w-1/2 items-center justify-center gap-4 relative">
                              <input
                                defaultValue={
                                  financeAttrb.elektronikusAdvanceValue
                                }
                                name="elektronikusAdvanceValue"
                                onChange={handleChange}
                                className="w-[35%]"
                                type="text"
                                placeholder="5000 Ft"
                              />

                              <input
                                defaultValue={
                                  financeAttrb.elektronikusAdvanceDate
                                }
                                name="elektronikusAdvanceDate"
                                onChange={handleChange}
                                className="w-[35%]"
                                type="text"
                                placeholder="2022-12-12"
                              />

                              {financeAttrb.idElektronikusAdvance === "" ? (
                                <div className="absolute right-[10px]">
                                  <FaCheck
                                    onClick={() => {
                                      onClickSaveSzakag({
                                        szakag: "elektronikus",
                                        isAdvance: true,
                                        value:
                                          financeAttrb.elektronikusAdvanceValue,
                                        date: financeAttrb.elektronikusAdvanceDate,
                                      });
                                    }}
                                    className="w-6 h-6 fill-green-600 cursor-pointer hover:rotate-6 hover:opacity-5"
                                  />
                                </div>
                              ) : (
                                <div className="absolute right-[10px]">
                                  {selectedRowID ===
                                  financeAttrb.idElektronikusAdvance ? (
                                    <FaTrashAlt
                                      onClick={() => {
                                        onClickDeleteFinance({
                                          projectUID:
                                            selectedProject["uuid_projekt"],
                                          paymentID:
                                            financeAttrb.idElektronikusAdvance,
                                          isAdvance: true,
                                        });
                                      }}
                                      className="w-6 h-6 fill-red-600 cursor-pointer hover:rotate-6 hover:opacity-5"
                                    />
                                  ) : (
                                    <FaPencilAlt
                                      onClick={() => {
                                        onClickEditRow({
                                          id: financeAttrb.idElektronikusAdvance,
                                          value:
                                            financeAttrb.elektronikusAdvanceValue,
                                          date: financeAttrb.elektronikusAdvanceDate,
                                          isAdvance: false,
                                        });
                                      }}
                                      className="w-6 h-6 fill-grey-600 cursor-pointer hover:rotate-6 hover:opacity-5"
                                    />
                                  )}
                                </div>
                              )}
                            </div>

                            <div className="flex w-1/2 items-center justify-center gap-4 relative">
                              <input
                                defaultValue={
                                  financeAttrb.elektronikusTotalValue
                                }
                                name="elektronikusTotalValue"
                                onChange={handleChange}
                                className="w-[35%]"
                                type="text"
                                placeholder="5000 Ft"
                              />

                              <input
                                defaultValue={
                                  financeAttrb.elektronikusTotalDate
                                }
                                name="elektronikusTotalDate"
                                onChange={handleChange}
                                className="w-[35%]"
                                type="text"
                                placeholder="2022-12-12"
                              />

                              {financeAttrb.idElektronikusTotal === "" ? (
                                <div className="absolute right-[10px]">
                                  <FaCheck
                                    onClick={() => {
                                      onClickSaveSzakag({
                                        szakag: "elektronikus",
                                        isAdvance: false,
                                        value:
                                          financeAttrb.elektronikusTotalValue,
                                        date: financeAttrb.elektronikusTotalDate,
                                      });
                                    }}
                                    className="w-6 h-6 fill-green-600 cursor-pointer hover:rotate-6 hover:opacity-5"
                                  />
                                </div>
                              ) : (
                                <div className="absolute right-[10px]">
                                  {selectedRowID ===
                                  financeAttrb.idElektronikusTotal ? (
                                    <FaTrashAlt
                                      onClick={() => {
                                        onClickDeleteFinance({
                                          projectUID:
                                            selectedProject["uuid_projekt"],
                                          paymentID:
                                            financeAttrb.idElektronikusTotal,
                                          isAdvance: true,
                                        });
                                      }}
                                      className="w-6 h-6 fill-red-600 cursor-pointer hover:rotate-6 hover:opacity-5"
                                    />
                                  ) : (
                                    <FaPencilAlt
                                      onClick={() => {
                                        onClickEditRow({
                                          id: financeAttrb.idElektronikusTotal,
                                          value:
                                            financeAttrb.elektronikusTotalValue,
                                          date: financeAttrb.elektronikusTotalDate,
                                          isAdvance: false,
                                        });
                                      }}
                                      className="w-6 h-6 fill-grey-600 cursor-pointer hover:rotate-6 hover:opacity-5"
                                    />
                                  )}
                                </div>
                              )}
                            </div>
                          </div>
                        </div>
                        {/* TABLE */}
                      </div>
                      {/* GÉPÉSZ, ELEKTRONIKUS TABLE */}
                    </>
                  ) : null}
                </div>
              ) : (
                <div className="flex w-full h-full items-center justify-center">
                  <LoadingSpinner />
                </div>
              )}
            </>
          )}
        </div>
      </div>
    </div>
  );
}
