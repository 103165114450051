import React, { useState } from "react";
import { useQuery } from "react-query";
import { toast } from "react-toastify";

import { formatDate } from "../../functions/helperFunctions";
import DropDown from "../../shared/DropDown";
import LoadingSpinner from "../../shared/loadingSpinner";
import { addNewProject, getAllColumns, getAllPersonsGrouped } from "../../shared/services";

export default function AddNewProject() {
  const { isFetching: loadingPersons, data: dataPersonsGrouped } = useQuery(
    "persons_grouped",
    getAllPersonsGrouped
  );

  const { isFetching: loadingColumns, data: columns } = useQuery(
    "columns",
    getAllColumns
  );

  // Create an array to hold the states and setters for each Dropdown
  const [inputValues, setInputValues] = useState({});
  const [dropdownStates, setDropdownStates] = useState([]);
  const [dropdownItems, setDropdownItems] = useState([]);

  // Function to handle input changes
  const handleChange = (event, colName) => {
    const { type, checked, value } = event.target;
  
    // Update inputValues object based on input type
    setInputValues(prevInputValues => ({
      ...prevInputValues,
      [colName]: type === 'checkbox' ? checked : value
    }));
  };

  const handleDropdownChange = (index, newItem) => {
    const newDropdownItems = { ...dropdownItems }; 
    newDropdownItems[columns[index].col_name] = newItem; 
    setDropdownItems(newDropdownItems);
  };

  // Function to toggle the dropdown state
  const toggleDropdown = (index) => {
      const newDropdownStates = [...dropdownStates];
      newDropdownStates[index] = !newDropdownStates[index];
      setDropdownStates(newDropdownStates);
  };

  const [loading, setLoading] = useState(false);
  const [date, setDate] = useState(formatDate(new Date()));

  async function onClickSaveProject() {
    const mandatoryFields = ["név", "város", "cím", "hrsz", "irányítószám"];

    setLoading(true);

    const missingFields = mandatoryFields.filter(fieldName => !inputValues[fieldName]);    

    if (missingFields.length > 0) {
      toast.error(
        "A név, város, utca, hrsz és irányítószám mező kitöltése kötelező!"
      );
      setLoading(false);
      return;
    }

    const projectData = {
      ...inputValues, // Include input values
    
      // Loop through dropdown columns to include in projectData
      ...columns.reduce((acc, col, colIndex) => {
        if (col.type === "szakag") {
          const selectedValue = dropdownItems[col.col_name];
          acc[col.col_name] = selectedValue || "Nincs még";
        }
        return acc;
      }, {}),
    
      "dátum": date, // Add additional fields, e.g., date
    };


    try {
      // Send post request
      await addNewProject({ project: projectData });
      toast.success("Sikeresen elmentve!");
      // Reset the form fields
      resetForm();
    } catch (error) {
      toast.error(error.message);
    } finally {
      setLoading(false);
    }
  }

  function resetForm() {
    setInputValues({});
    setDropdownStates([]);
    setDropdownItems({});
    setDate(formatDate(new Date()));
  };

  if(!loadingPersons && !loadingColumns)
  {
  return (
    <div className="m-10">
        <form action="#" className="relative bg-white rounded-lg shadow">

        <div className="flex flex-col gap-5 w-full h-full">
          <div className="flex justify-between items-start p-4 rounded-t border-b">
            <h3 className="text-xl font-semibold text-gray-900">
              Új projekt hozzáadása
            </h3>
          </div>
        
          <p className='font-bold text-lg px-4'>Általános adatok</p>

          <div className="flex flex-wrap gap-5 w-full h-full px-4">
                {columns.map((col, colIndex) => {
                    if (col.type === "string" && col.col_name !== "állapot") {
                        return(                        
                            <div key={colIndex} className="flex flex-col w-[20%]">
                                <label type="label">{col.display_name}</label>
                                    <input
                                      name={col.col_name}
                                      onChange={event => handleChange(event, col.col_name)} // Pass the column name to handleChange
                                      type="text"
                                      placeholder="..."
                                    />
                            </div>
                        )
                    }
                })}
            </div>

            <p className='font-bold text-lg px-4'>Szakágak</p>

            <div className="flex flex-wrap gap-5 w-full h-full px-4">
                {columns.map((col, colIndex) => {       
                if (col.type === "szakag") {
                        return(                        
                            <div key={colIndex} className="flex flex-col w-[20%]">
                                <DropDown
                                array={dataPersonsGrouped[col.display_name]}
                                title={col.display_name}
                                state={dropdownStates[colIndex] || false}
                                setState={() => toggleDropdown(colIndex)}
                                item={dropdownItems[col.col_name]}
                                setItem={(newItem) => handleDropdownChange(colIndex, newItem)}
                                selectedValue={dropdownItems[col.col_name]}
                                />
                            </div>
                        )
                    }
                })}
            </div>

            <p className='font-bold text-lg px-4'>Jelölőnégyzetek</p>

            <div className="flex flex-wrap gap-5 w-full h-full px-4">
                {columns.map((col, colIndex) => {
                    if (col.type === "checkbox") {
                        return(                        
                            <div key={colIndex} className="flex flex-col w-[20%]">
                                <label type="label">{col.display_name}</label>
                                <input
                                  className="flex w-5 h-5 items-center"
                                  checked={inputValues[col.col_name] || false} // Use checked value from inputValues object
                                  onChange={event => handleChange(event, col.col_name)} // Pass column name to handleChange
                                  type="checkbox"
                                />
                            </div>
                        )
                    }
                })}
            </div>


            <div className="flex flex-col w-[20%] px-4">
              <label type="label">Dátum</label>
                  <input
                  name={"dátum"}
                  onChange={handleChange}
                  defaultValue={date}
                  type="text"
                  />
            </div>


            <div className="flex items-center p-4 space-x-2 rounded-b border-t border-gray-200">
              {loading ? (
                <LoadingSpinner />
              ) : (
                <button onClick={onClickSaveProject} type="button">
                  Mentés
                </button>
              )}
            </div> 
        
        </div>
        </form>
    </div>
  );
}
else{
  <LoadingSpinner/>
}
}
