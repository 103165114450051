import { useContext, useEffect, useState } from "react";
import {FaAddressBook,FaChartBar, FaHome, FaArrowRight, FaFolderPlus } from "react-icons/fa"
import { BsGearFill } from "react-icons/bs"
import { TbZoomMoney } from "react-icons/tb"
import {BsFillPersonPlusFill} from 'react-icons/bs'
import { Link } from "react-router-dom";
import classNames from "classnames";
import { UserContext } from "../../context/UserContext";
import Tippy from "@tippyjs/react";

const  LINK_NAME = "flex items-center my-2 gap-2 font-bold px-1 py-2 hover:bg-orange-300 hover:no-underline active:bg-orange-500 rounded-sm text-base"

export default function SideBar({location}){
    const [open,setOpen] = useState(false)
    const [, setToken] = useContext(UserContext);
    const [image,setImage] = useState(null)

    const ICON_STYLE = "w-8 h-8"
    const USER_ROLE = localStorage.getItem('role')



    useEffect(()=>{
        let avatar_url = "https://api.dicebear.com/5.x/bottts-neutral/svg?seed="+localStorage.getItem('username')

        fetch(avatar_url)
        .then((response) => setImage(response.url))
        .catch((err) => console.log(err));
    },[])


    const MENUS = [
        {key:0,role:["USER","MANAGER","ADMIN"],title:"Dashboard", path:"/",icon: <FaChartBar className={ICON_STYLE}/>},
        {key:1,role:["USER","MANAGER","ADMIN"],title:"Projektek", path:"/projects",icon: <FaHome className={ICON_STYLE}/>},
        {key:2,role:["USER","MANAGER","ADMIN"],title:"Új projekt", path:"/add_new_project", icon: <FaFolderPlus className={ICON_STYLE}/>},
        {key:3,role:["USER","MANAGER","ADMIN"],title:"Szakági ügyfelek", path:"/address_book", icon: <FaAddressBook className={ICON_STYLE}/>},
        {key:4,role:["USER","MANAGER","ADMIN"], title:"Új szakági ügyfél", path:"/add_new_person",icon: <BsFillPersonPlusFill className={ICON_STYLE}/>},
        {key:5,role:["MANAGER","ADMIN"], title:"Pénzügyek", path:"/finance",icon: <TbZoomMoney className={ICON_STYLE}/>},
        {key:6,role:["USER","MANAGER","ADMIN"],title:"Beállítások", path:"/settings",icon: <BsGearFill className={ICON_STYLE}/>},
    ]

    return(
        <div className="flex">
            <div className={`${open ? "w-72" : "w-20"} bg-gradient-to-t from-gray-800 to-gray-600 h-full p-5 pt-8 relative duration-500 shadow-md`}>
                <div className="h-[20%] w-full ">
                
                <div className={`flex absolute items-center justify-center z-50 h-7 w-7 cursor-pointer right-6 top-2.5 shadow-sm bg-orange-500 border-black border-1 rounded-full ${open && "rotate-180"}`}>
                    <FaArrowRight 
                        className={`h-5 w-5 fill-gray-800`}
                        onClick={()=> setOpen(!open)}
                    />
                </div>


                    <div className="flex gap-x-4 items-center">
                        <img
                        alt=""
                        src={require('../../assets/icons/logo.png')}
                        className={`rounded-sm object-contain h-20 w-20 duration-500`}
                        />
                        <h1 className={`text-white origin-left font-['Segoe UI'] font-bold text-xl duration-200 ${!open && "scale-0"}`}>
                            Estetica CRM
                        </h1>
                    </div>
                </div>

                <div className="flex h-[60%] w-full items-center">
                    <div className="pt-6">
                        {MENUS.map((item,index)=>(
                            <div key={index}>
                            {
                            item.role.includes(USER_ROLE) ?
                            <SideBarLink location={location} item={item} open={open}/>
                            : null
                            }
                            </div>
                        ))}
                    </div>
                </div>

                <div className="flex gap-x-4 h-[20%] w-full items-end">
                    
                        <Tippy content="Kijelentkezés" delay={300}>
                            <img
                                onClick={()=>{setToken(null)}}
                                className="h-8 items-center justify-center cursor-pointer"
                                src={image}
                                alt="avatar"
                            />
                        </Tippy>

                        <h1 className={`text-white origin-left font-['Segoe UI'] font-bold text-xl duration-200 ${!open && "scale-0"}`}>
                        {localStorage.getItem("username") ?? ""}
                        </h1>
                    
                </div>
            </div>
        </div>
    )
}

function SideBarLink({ location, item, open }){
    return(
        <Link to={item.path} className={classNames(location === item.path ? 'text-white bg-orange-500' : 'text-gray-500', LINK_NAME)}>
            <span className="hover:rotate-6">{item.icon}</span>
            
            <span className={`${!open && "hidden"} font-['Segoe UI'] origin-left duration-200`}>
            {item.title}
            </span>

        </Link>
    )

}