import React from "react";
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';
import { Doughnut } from 'react-chartjs-2'
import { useQuery } from "react-query";
import { getHitStats } from "../../shared/services";
import LoadingSpinner from "../../shared/loadingSpinner";

ChartJS.register(ArcElement, Tooltip, Legend);

export const options = {
    responsive: true,
    plugins:{
        legend:{
            position:'top'
        }
    },
    title:{
        display:false,
        text:'Title'
    }
}



export default function HitsBarChart(){
    const { data: hitsData } = useQuery('hit_stats', getHitStats)
    

    return(
        <div className="flex flex-col w-full h-full rounded-md shadow-md bg-white my-4">
            
            
                <div className="flex items-center p-2 h-14 w-full border-b-2">
                    <h1 className="font-['Segoe UI'] font-bold text-lg">Basztatások száma (top 5)</h1>
                </div>

                {hitsData  ? 
                <div className="relative flex h-full p-2 text-white items-center justify-center">
                    <div className="flex w-96 h-96">
                        <Doughnut data={hitsData.dataset} />;
                    </div>

                    <div className="absolute flex flex-col items-center justify-center pt-[5rem]">
                        <p type="label" className="text-2xl">Összesen</p>
                        <p type="label" className="text-xl">{hitsData.allHit} db</p>
                    </div>
                </div>
            
                :
                <div className="flex w-full h-full items-center justify-center">
                    <LoadingSpinner/>
                </div>
                }
		</div>
    )
}