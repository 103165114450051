import React, { useEffect, useState } from "react";
import DropDown from "../../shared/DropDown";
import LoadingSpinner from "../../shared/loadingSpinner";
import { toast } from "react-toastify";
import { addNewPerson, getProfessions } from "../../shared/services";
import { useQuery } from "react-query";

export default function AddNewPerson() {
  const [loading, setLoading] = useState(false);

  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");

  const [dropDownOpen, setDropDownOpen] = useState(false);
  const [dropDownSelected, setDropDownSelected] = useState(null);

  const { isFetching: loadingProfessions, data: professions } = useQuery(
    "professions",
    getProfessions
  );


  async function onClickSavePerson() {
    setLoading(true);
    //Checking mandatory input if missing
    if (!name || !dropDownSelected) {
      toast.warn("Név és szakág mezők kitöltése kötelező!");
      setLoading(false);
      return
    }

    //Send post request
    await addNewPerson({
      name: name,
      phone: phone === "" ? "---" : phone,
      email: email === "" ? "---" : email,      
      szakag: dropDownSelected,
    })
      .then(() => {
        setName("");
        setPhone("");
        setEmail("");
        setDropDownSelected(null);

        setLoading(false);
        toast.success("Sikeresen elmentve!", {toastId: 'success1'});
      })
      .catch((error) => {
        setLoading(false);
        toast.error(error.message);
      });
  }

  return (
    <div className="m-10">
      <form action="#" className="relative bg-white rounded-lg shadow">
        <div className="flex justify-between items-start p-4 rounded-t border-b">
          <h3 className="text-xl font-semibold text-gray-900">
            Új szakági ügyfél hozzáadása
          </h3>
        </div>

        <div className="p-6 space-y-6">
          <div className="grid grid-cols-6 gap-6">
            <div className="col-span-6 sm:col-span-3">
              <label type="label">Név</label>
              <input
                onChange={(e) => setName(e.target.value)}
                type="text"
                placeholder="Bonnie"
                value={name}
              />
            </div>

            <div className="col-span-6 sm:col-span-3">
              <label type="label">E-mail</label>
              <input
                onChange={(e) => setEmail(e.target.value)}
                type="text"
                placeholder="grafit@gmail.com"
                value={email}
              />
            </div>

            <div className="col-span-6 sm:col-span-3">
              <label type="label">Telefonszám</label>
              <input
                onChange={(e) => setPhone(e.target.value)}
                type="text"
                placeholder="+36 20 11111111"
                value={phone}
              />
            </div>

            {loadingProfessions ? null : 
            <DropDown
              title="Szakág"
              array={professions.map(item => item.prof_name)}
              state={dropDownOpen}
              setState={setDropDownOpen}
              item={dropDownSelected}
              setItem={setDropDownSelected}
              placeholder="Kiválasztás"
              dropDownSize="w-[15%]"
            />
            }
          </div>
        </div>

        <div className="flex items-center p-6 space-x-2 rounded-b border-t border-gray-200">
          {loading ? (
            <LoadingSpinner />
          ) : (
            <button onClick={onClickSavePerson} type="button">
              Mentés
            </button>
          )}
        </div>
      </form>
    </div>
  );
}
