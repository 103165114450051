export default function ProjectDetailsNotes({notes, setNotes}) {
  return (
    <div className="flex flex-col h-full w-full">

      <label className="font-bold text-xl text-center" type="label">
        Jegyzetek
      </label>

      <div className="m-2">
        <textarea
          style={{resize: 'none'}}
          rows="25"
          type="textarea"
          placeholder="Jegyzet helye..."
          value={notes}
          onChange={(e) => {setNotes(e.target.value)}}
        />
      </div>

    </div>
  );
}
