export default function DropDown({
  array,
  title,
  state,
  setState,
  item,
  setItem,
  placeholder,
  dropDownSize,
  style
}) {
  return (
    <div className={style}>
      {title ? <label type="label">{title}</label> : null}
      <button
        onClick={(e) => {
          e.preventDefault(); //default behavior of the button is submit, this stops reloading the page
          setState(!state);
        }}
        className="inline-flex w-full text-left"
        type="dropdown"
      >
        {item ? item : placeholder ? placeholder : "Nincs még"}

        <svg
          className="ml-auto  w-4 h-4"
          aria-hidden="true"
          fill="none"
          stroke="currentColor"
          viewBox="0 0 24 24"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="2"
            d="M19 9l-7 7-7-7"
          ></path>
        </svg>
      </button>

      <div
        className={`${state ? "" : "hidden"} absolute z-10 ${
          dropDownSize ? dropDownSize : "w-[30%]"
        }  bg-white rounded divide-y divide-gray-100 shadow 
        ${array.length >= 4 ? "h-[200px]" : "mx-auto"} 
         overflow-y-scroll no-scrollbar`}
      >
        {array.map((item, index) => (
          <ul
            onClick={() => {
              setItem(item);
              setState(false);
            }}
            key={index}
            className="py-1 text-sm text-gray-700"
          >
            <li className="block py-2 px-4 hover:bg-gray-100">{item}</li>
          </ul>
        ))}
      </div>
    </div>
  );
}
