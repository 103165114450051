import React from 'react'
import RenderColumnSettings from './ColumnSettings/RenderColumnSettings'
import RenderUsers from './RenderUsers'

export default function Settings() {
  const USER_ROLE = localStorage.getItem('role')

  return (
    <div className='flex h-screen justify-center overflow-y-scroll'>
      <div className='flex flex-col w-[95%]'>

          {["ADMIN"].includes(USER_ROLE) ? <RenderUsers/> : null}

          {["MANAGER","ADMIN", "USER"].includes(USER_ROLE) ? <RenderColumnSettings/> : null}

      </div>
    </div>
  )
}
