import React, { useEffect, useState } from "react";
import { FaTrashAlt, FaUserTie, FaHome, FaTools  } from "react-icons/fa";
import { useQuery } from "react-query";
import { toast } from "react-toastify";
import LoadingSpinner from "../../shared/loadingSpinner";
import { addHit, deleteHit, getHitPersonData, savePersonData } from "../../shared/services";

const HEADER_STYLE = "w-1/2 text-left font-xl font-bold";

export default function RenderHitPerson({
  openBasztatas,
  setOpenBasztatas,
  selectedProject,
  column,
}) {

  const [personAttrb, setPersonAttrb] = useState({
    name:"",
    phone:"",
    email:""
  })

  const {
    data:dataHit,
    isFetching:fetchingHit,
    refetch:refetcHit,
  } = useQuery(
    ["hit", selectedProject["uuid_projekt"],column,selectedProject[column]],
    () => getHitPersonData(selectedProject["uuid_projekt"], column ,selectedProject[column]), //BUG IS HERE: selectedProject["szeglemezes statikus"] not exists
    { enabled: openBasztatas, cacheTime:0, onError: () => {onNoData()}},
  );

  function onNoData(){
    toast.error(`Ez a névjegy hiányzik a névjegyzékből!`)
    setOpenBasztatas(false)
  }

  useEffect(()=>{
    if (dataHit) {
      setPersonAttrb({
        name:dataHit.data['név'],
        phone:dataHit.data['telefon'],
        email:dataHit.data['email']
      })  
    }
  },[dataHit])


  function handleChange(evt) {
    const value = evt.target.value;
    setPersonAttrb({
      ...personAttrb,
      [evt.target.name]: value
    });
  }

  
  async function onClickHitEnd() {
    await addHit({endHit:true, projectUID:selectedProject["uuid_projekt"], name:selectedProject[column], column:column})
    .then((res)=>{
      refetcHit()
      toast.success("Hozzáadva!", {toastId: 'success21'})
    })
    .catch((err) => {
      toast.error("Hiba!\n" + err);
    });
  }

  async function onClickDeleteHit(id,index, itemName) {
    if(!dataHit.onGoing && itemName === 'Basztatás'){
      toast.warning("A basztatás le lett zárva! Ez esetben csak a legutolsó elemet lehet kitörölni!");
      return
    }

    await deleteHit({uid:id, index:index})
    .then((res)=>{
      refetcHit()
      toast.success("Eltávolítva!", {toastId: 'success22'})
    })
    .catch((err) => {
      toast.error("Hiba!\n" + err);
    });
  }

  async function onClickHit() {
    await addHit({endHit:false, projectUID:selectedProject["uuid_projekt"], name:selectedProject[column], column:column})
    .then(()=>{
      refetcHit()
      toast.success("Hozzáadva!", {toastId: 'success23'})
    })
    .catch((err) => {
      toast.error("Hiba!\n" + err);
    });
  }

  async function onClickSave() {
    await savePersonData({id:dataHit.data._id,name:personAttrb.name, phone:personAttrb.phone, email:personAttrb.email})
    .then(()=>{
      setOpenBasztatas(!openBasztatas);
      })
    .catch((err) => {
      toast.error("Hiba!\n" + err);
    });
  }


  return (
    <>
      <div
        className={`${
          openBasztatas ? "w-full h-full" : "h-0 w-0"
        } duration-200 bottom-0 backdrop-blur-sm absolute`}
      ></div>

      <div
        className={`${
          openBasztatas ? "h-[35rem]" : "h-0"
        } w-full duration-500 absolute bottom-0 z-20 rounded-md shadow-md bg-gray-100 flex flex-col justify-between items-center`}
      >
        <div className="flex flex-col">
          <div className="flex flex-row items-center gap-5 justify-center">
          <FaHome className="w-5 h-5 fill-gray-600"/>
            <label
            className={`${
              openBasztatas ? "" : "scale-0"
            } text-2xl font-bold text-center`}
            type="labe"
            >
              {selectedProject["név"]}
            </label>

            <FaUserTie className="w-5 h-5 fill-gray-600"/>
            <label
            className={`${
              openBasztatas ? "" : "scale-0"
            } text-2xl font-bold text-center`}
            type="labe"
            >
              {selectedProject[column]}
            </label>

            <FaTools className="w-5 h-5 fill-gray-600"/>
            <label
            className={`${
              openBasztatas ? "" : "scale-0"
            } text-2xl font-bold text-center`}
            type="labe"
            >
              {column}
            </label>
          
          </div>

          

          {dataHit && !fetchingHit ?
          <div className="flex flex-row  gap-[30px] justify-center w-full pt-10">
            <div className="flex flex-col gap-5">
              <div className="flex flex-row items-center justify-center">
                <label className="w-44" type="label">
                  Név
                </label>
                <input 
                type="text"
                name="name" 
                defaultValue={dataHit.data['név']}
                onChange={handleChange}
                />
              </div>

              <div className="flex flex-row items-center justify-center">
                <label className="w-44" type="label">
                  Telefonszám
                </label>
                <input 
                type="text"
                name="phone" 
                defaultValue={dataHit.data['telefon']}
                onChange={handleChange}
                />              
                </div>

              <div className="flex flex-row items-center justify-center">
                <label className="w-44" type="label">
                  Email
                </label>
                <input 
                type="text"
                name="meail" 
                defaultValue={dataHit.data['email']}
                onChange={handleChange}
                />  
                </div>
            </div>

            <div className="flex flex-col">
              <div className="flex flex-row items-center justify-center px-2 py-2 shadow-md sm:rounded-lg bg-gray-400">
                <p className={HEADER_STYLE}>Esemény</p>
                <p className={HEADER_STYLE}></p>
              </div>

              {/* BODY */}
              <div className="flex flex-col h-40 w-96 overflow-y-scroll no-scrollbar">
                {dataHit.hits
                .sort((a, b) => new Date(a.date) - new Date(b.date))
                .map((item, index) => (
                  <div
                    key={index}
                    className="flex flex-row h-10 px-2 py-2 items-center justify-center border-b-2"
                  >
                    <div className="flex flew-row w-1/2 gap-2">
                      <p className="">{item.name}</p>
                      <p className="">-</p>
                      <p className="">{item.date}</p>
                    </div>

                    <div className="flex w-1/2 pr-4 justify-end">
                      <FaTrashAlt
                        onClick={() => {onClickDeleteHit(item.uid, index, item.name)}}
                        className="w-5 h-5 fill-red-600 hover:fill-red-300 hover:rotate-6 cursor-pointer"
                      />
                    </div>
                  </div>
                ))}
              </div>

              <div className="flex flex-row gap-2 items-center justify-center">
                <button
                  disabled={dataHit.onGoing !== null ? !dataHit.onGoing : false }
                  onClick={onClickHit}
                  type="button"
                  className="my-4 w-[10rem] bg-red-500 hover:bg-red-300 items-center justify-center disabled:bg-gray-300"
                >
                  Basztatás
                </button>

                <button
                  disabled={dataHit.onGoing !== null ? !dataHit.onGoing : false}
                  onClick={onClickHitEnd}
                  type="button"
                  className="my-4 w-[10rem] bg-green-500 hover:bg-green-300 items-center justify-center disabled:bg-gray-300"
                >
                  Basztatás vége
                </button>
              </div>
            </div>
          </div>
          
          : 
          <div className="flex w-full h-10 items-center justify-center pt-10">
            <LoadingSpinner/>
          </div>
          }

        </div>

        <div className="flex items-center justify-center">
            <button
              onClick={(e) => {
                onClickSave(e);
              }}
              type="button"
              className="my-4 mb-[100px] w-[10rem] bg-blue-700 hover:bg-blue-400 items-center justify-center"
            >
              {/* <FaSave className="w-5 h-5 fill-white" /> */}
              Bezárás
            </button>
        </div>
      </div>
    </>
  );
}
