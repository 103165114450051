import { useState } from "react";
import { FaTrashAlt } from "react-icons/fa";
import { FiArrowUp, FiArrowDown } from "react-icons/fi";
import DropDown from "../../../shared/DropDown";
import LoadingSpinner from "../../../shared/loadingSpinner";
import { FaPencilAlt, FaCheck } from "react-icons/fa";
import Tippy from "@tippyjs/react";
import { toast } from "react-toastify";
import {
  addNewEventToProject,
  deleteEvent,
  editEvent,
  getEvents,
  moveEvent,
} from "../../../shared/services";
import { useQuery } from "react-query";
import { formatDate, validateDate } from "../../../functions/helperFunctions";

const HEADER_STYLE = "w-1/2 font-['Segoe UI'] text-center font-xl font-bold";

export default function ProjectDetailsEvents({ selectedProject, openEvents }) {
  const [addNewEvent, setAddNewEvent] = useState(false);
  const [addNewEventName, setAddNewEventName] = useState("");
  const [addNewEventDate, setAddNewEventDate] = useState(
    formatDate(new Date())
  );
  const [eventTypeSelector, setEventTypeSelector] = useState(false);
  const [eventType, setEventType] = useState(null);
  const [buttonLoading, setButonLoading] = useState(false);

  const [selectedRowEvents, setSelectedRowEvents] = useState([]);
  const [selectedRowEventsOut, setSelectedRowEventsOut] = useState([]);

  const [clickedRowID, setClickedRowID] = useState(null);
  const [eventAttrb, setEventAttrb] = useState({
    name: "",
    startDate: "",
    endDate: "",
  });

  const { data: dataEvents, refetch: refetchEvents } = useQuery(
    ["project_events", selectedProject["uuid_projekt"]],
    () => getEvents(selectedProject["uuid_projekt"]),
    { enabled: openEvents, cacheTime: 0 }
  );

  async function onClickAddNewEvent() {
    if (!eventType || addNewEventName === "") {
      toast.warn("Az esemény neve, vagy státusza hiányzik!");
      return;
    }

    // setButonLoading(true);
    let isOut;
    if (eventType === "Engedélyezési terv") {
      isOut = false;
    } else {
      isOut = true;
    }

    await addNewEventToProject({
      projectUID: selectedProject["uuid_projekt"],
      eventName: addNewEventName,
      startDate: addNewEventDate,
      isOut: isOut,
      user: localStorage.getItem("username"),
    })
      .then(() => {
        refetchEvents();
        toast.success("Sikeresen hozzáadva!", { toastId: "success14" });
        setButonLoading(false);
        setAddNewEvent(!addNewEvent);
      })
      .catch((err) => {
        toast.error("Hiba!\n" + err);
        setButonLoading(false);
      });
  }

  function onClickEventsCheckbox(event, item, eventsOutClicked) {
    if (!eventsOutClicked) {
      let isChecked = event.target.checked;
      if (isChecked === true) {
        setSelectedRowEvents((current) => [...current, item]);
      } else {
        let filteredArray = selectedRowEvents.filter(
          (event) => event._id !== item._id
        );
        setSelectedRowEvents(filteredArray);
      }
    } else {
      let isChecked = event.target.checked;
      if (isChecked === true) {
        setSelectedRowEventsOut((current) => [...current, item]);
      } else {
        let filteredArray = selectedRowEvents.filter(
          (event) => event._id !== item._id
        );
        setSelectedRowEventsOut(filteredArray);
      }
    }
  }

  function handleChange(evt) {
    const value = evt.target.value;
    setEventAttrb({
      ...eventAttrb,
      [evt.target.name]: value,
    });
  }

  function onClickEditEvents(event) {
    setClickedRowID(event._id);
    setEventAttrb({
      name: event["esemeny"],
      startDate: event["kezdo_date"],
      endDate: event["vege_date"],
    });
  }

  async function onClickSaveEdit(id, isOut) {
    if (validateDate(eventAttrb.startDate)) {
      toast.warn(
        "Az esemény dátumának formátuma nem megfelelő! Pl.: '2023-01-01'"
      );
      return;
    }

    await editEvent({
      eventID: id,
      eventName: eventAttrb.name,
      startDate: eventAttrb.startDate,
      endDate: eventAttrb.endDate,
      isOut: isOut,
    })
      .then(() => {
        refetchEvents();
        toast.success("Sikeresen elmentve!", { toastId: "success15" });
        setClickedRowID(null);
      })
      .catch((err) => {
        toast.error("Hiba!\n" + err);
        setClickedRowID(null);
      });

    setEventAttrb({
      name: "",
      startDate: "",
      endDate: "",
    });
  }

  async function onClickDeleteEvent(id, isOut) {
    var answer = window.confirm("Biztosan törölni szeretnéd ezt az eseményt?");
    if (answer) {
      await deleteEvent({ eventID: id, isOut: isOut })
        .then(() => {
          refetchEvents();
          toast.success("Sikeresen törölve!", { toastId: "success17" });
          setClickedRowID(null);
        })
        .catch((err) => {
          toast.error("Hiba!\n" + err);
          setClickedRowID(null);
        });
    } else {
      setClickedRowID(null);
    }
  }

  async function moveEvents(direction) {
    if (selectedRowEvents.length === 0 && selectedRowEventsOut.length === 0) {
      toast.error("Nincs esemény kiválasztva!");
      return;
    }

    //Engedélyezési --> Kiviteli
    if (direction === "up") {
      await moveEvent({ eventsArray: selectedRowEventsOut, direction: "up" })
        .then(() => {
          refetchEvents();
          toast.success("Sikeresen áthelyezve!", { toastId: "success18" });
        })
        .catch((err) => {
          toast.error("Hiba!\n" + err);
        });
    }
    //Kiviteli --> Engedélyezési
    else {
      await moveEvent({ eventsArray: selectedRowEvents, direction: "down" })
        .then(() => {
          refetchEvents();
          toast.success("Sikeresen áthelyezve!", { toastId: "success19" });
        })
        .catch((err) => {
          toast.error("Hiba!\n" + err);
        });
    }

    //Clearing states
    setSelectedRowEventsOut([]);
    setSelectedRowEvents([]);

    //Setting all checkbox to false
    let x = document.querySelectorAll("input[type=checkbox]");
    for (let i = 0; i < x.length; i++) {
      x[i].checked = false;
    }
  }

  if (!dataEvents) {
    return (
      <div className="flex w-full h-full items-center justify-center">
        <LoadingSpinner />
      </div>
    );
  } else {
    return (
      <div className="h-full w-full overflow-auto no-scrollbar">
        <div className="flex justify-center items-center">
          {!addNewEvent ? (
            <button
              className="bg-blue-600"
              type="button"
              onClick={() => {
                setAddNewEvent(true);
              }}
            >
              Új esemény hozzáadása
            </button>
          ) : null}

          {addNewEvent ? (
            <div>
              <button
                className={`bg-green-600`}
                type="button"
                onClick={() => {
                  onClickAddNewEvent();
                }}
                disabled={buttonLoading}
              >
                {buttonLoading ? <LoadingSpinner /> : "Hozzáadás"}
              </button>

              <button
                onClick={() => {
                  setAddNewEvent(false);
                }}
                className="bg-red-600 ml-4"
                type="button"
                disabled={buttonLoading}
              >
                Mégse
              </button>
            </div>
          ) : null}
        </div>

        <div>
          {addNewEvent ? (
            <div className="flex flex-row gap-4 pt-4 pb-4">
              <input
                className="flex-auto"
                placeholder="Esemény neve..."
                onChange={(e) => setAddNewEventName(e.target.value)}
                type="text"
              />
              <input
                className="w-1/2"
                placeholder="Dátum"
                type="text"
                onChange={(e) => setAddNewEventDate(e.target.value)}
                value={formatDate(new Date())}
                disabled={true}
              />
              <div className="w-3/4">
                <DropDown
                  dropDownSize={"w-[20%]"}
                  array={["Engedélyezési terv", "Kiviteli terv"]}
                  placeholder={"Státusz"}
                  state={eventTypeSelector}
                  setState={setEventTypeSelector}
                  item={eventType}
                  setItem={setEventType}
                />
              </div>
            </div>
          ) : null}
        </div>

        <label className="pt-5 pb-5 font-bold text-xl" type="label">
          Engedélyezési terv
        </label>

        <div className="flex flex-col">
          {/* ESEMENYEK TABLE */}
          {/* HEADER */}
          <div className="flex flex-row items-center justify-center px-2 py-2 shadow-md sm:rounded-lg bg-gray-400">
            <p className="w-20"></p>
            <p className="w-20"></p>

            <p className="w-4/5 font-['Segoe UI'] text-left font-xl font-bold">
              Esemény
            </p>
            <p className={HEADER_STYLE}>Esemény kezdete</p>
            <p className={HEADER_STYLE}>Esemény vége</p>
          </div>

          {/* BODY */}
          <div
            className={`${
              dataEvents["events"].length >= 4
                ? "h-56 overflow-y-scroll no-scrollbar"
                : "mx-auto"
            } flex flex-col w-full`}
          >
            {dataEvents["events"]
              .sort(
                (a, b) => new Date(b["kezdo_date"]) - new Date(a["kezdo_date"])
              )
              .map((item, index) => (
                <div
                  key={index}
                  className={`${
                    selectedRowEvents.some((e) => e._id === item._id)
                      ? "bg-orange-400"
                      : ""
                  } 
                ${
                  item["esemeny"].includes("Basztatás") &&
                  item["vege_date"] !== "---"
                    ? "bg-green-400"
                    : item["esemeny"].includes("Basztatás")
                    ? "bg-red-400"
                    : ""
                }
                flex flex-row h-10 px-2 py-6 items-center justify-center border-b-2`}
                >
                  <div className="flex w-20 items-center">
                    {clickedRowID === item._id ? (
                      <FaTrashAlt
                        onClick={() => {
                          onClickDeleteEvent(item._id, false);
                        }}
                        className="w-6 h-6 fill-red-600 cursor-pointer hover:rotate-6 hover:opacity-5"
                      />
                    ) : (
                      <>
                        {item["esemeny"].includes("Basztatás") ? null : (
                          <input
                            className="w-5 h-5"
                            onChange={(e) =>
                              onClickEventsCheckbox(e, item, false)
                            }
                            type="checkbox"
                          />
                        )}
                      </>
                    )}
                  </div>

                  <div className="flex w-20 items-center">
                    {clickedRowID === item._id ? (
                      <FaCheck
                        className="w-6 h-6 fill-green-600 cursor-pointer hover:rotate-6 hover:opacity-5"
                        onClick={() => {
                          onClickSaveEdit(item._id, false);
                        }}
                      />
                    ) : (
                      <>
                        {item["esemeny"].includes("Basztatás") ? null : (
                          <FaPencilAlt
                            className="w-6 h-6 cursor-pointer hover:rotate-6 hover:opacity-5 fill-black"
                            onClick={() => {
                              onClickEditEvents(item);
                            }}
                          />
                        )}
                      </>
                    )}
                  </div>

                  <div className="flex w-4/5">
                    {clickedRowID === item._id ? (
                      <input
                        className="w-full"
                        type="text"
                        name="name"
                        onChange={handleChange}
                        onKeyDown={(e) => {
                          if (e.key === "Enter")
                            onClickSaveEdit(item._id, false);
                        }}
                        defaultValue={eventAttrb.name}
                      />
                    ) : (
                      <p className="text-left">{item["esemeny"]}</p>
                    )}
                  </div>

                  <div className="flex w-1/2 items-center justify-center">
                    {clickedRowID === item._id ? (
                      <input
                        className="w-full"
                        type="text"
                        name="startDate"
                        onChange={handleChange}
                        onKeyDown={(e) => {
                          if (e.key === "Enter")
                            onClickSaveEdit(item._id, false);
                        }}
                        defaultValue={eventAttrb.startDate}
                      />
                    ) : (
                      <p className="text-center">{item["kezdo_date"]}</p>
                    )}
                  </div>

                  <div className="flex w-1/2 items-center justify-center">
                    {clickedRowID === item._id ? (
                      <input
                        className="w-full"
                        type="text"
                        name="endDate"
                        onChange={handleChange}
                        defaultValue={eventAttrb.endDate}
                      />
                    ) : (
                      <p className="text-center">{item["vege_date"]}</p>
                    )}
                  </div>
                </div>
              ))}
          </div>
          {/* ESEMENYEK TABLE */}

          {/* ARROWS */}
          <div className="flex flex-row justify-evenly py-5">
            <Tippy content="Esemény(ek) mozgatása fel" delay={300}>
              <div>
                <FiArrowUp
                  onClick={() => moveEvents("up")}
                  className={`${
                    selectedRowEventsOut.length >= 1
                      ? "bg-orange-500 rounded-full shadow-md shadow-orange-400 cursor-pointer"
                      : ""
                  } w-8 h-8`}
                />
              </div>
            </Tippy>

            <Tippy content="Esemény(ek) mozgatása le" delay={300}>
              <div>
                <FiArrowDown
                  onClick={() => moveEvents("down")}
                  className={`${
                    selectedRowEvents.length >= 1
                      ? "bg-orange-500 rounded-full shadow-md shadow-orange-400 cursor-pointer"
                      : ""
                  } w-8 h-8`}
                />
              </div>
            </Tippy>
          </div>
          {/* ARROWS */}

          <label className="py-5 font-bold text-xl" type="label">
            Kiviteli terv
          </label>

          {/* ESEMENYEK KIVITEL TABLE */}
          {/* HEADER */}
          <div className="flex flex-row items-center justify-center px-2 py-2 shadow-md sm:rounded-lg bg-gray-400">
            <p className="w-20"></p>
            <p className="w-20"></p>
            <p className="w-4/5 font-['Segoe UI'] text-left font-xl font-bold">
              Esemény
            </p>
            <p className={HEADER_STYLE}>Esemény kezdete</p>
            <p className={HEADER_STYLE}>Esemény vége</p>
          </div>

          {/* BODY */}
          <div
            className={`${
              dataEvents["events_out"].length >= 4
                ? "h-40 overflow-y-scroll no-scrollbar"
                : "mx-auto"
            } flex flex-col w-full`}
          >
            {dataEvents["events_out"]
              .sort(
                (a, b) => new Date(b["kezdo_date"]) - new Date(a["kezdo_date"])
              )
              .map((item, index) => (
                <div
                  key={index}
                  className={`${
                    selectedRowEventsOut.some((e) => e._id === item._id)
                      ? "bg-orange-400"
                      : ""
                  }
                flex flex-row h-10 px-2 py-6 items-center justify-center border-b-2
                `}
                >
                  <div className="flex w-20 items-center">
                    {clickedRowID === item._id ? (
                      <FaTrashAlt
                        onClick={() => {
                          onClickDeleteEvent(item._id, true);
                        }}
                        className="w-6 h-6 fill-red-600 cursor-pointer hover:rotate-6 hover:opacity-5"
                      />
                    ) : (
                      <>
                        {item["esemeny"].includes("Basztatás") ? null : (
                          <input
                            className="w-5 h-5"
                            onChange={(e) =>
                              onClickEventsCheckbox(e, item, true)
                            }
                            type="checkbox"
                          />
                        )}
                      </>
                    )}
                  </div>

                  <div className="flex w-20 items-center">
                    {clickedRowID === item._id ? (
                      <FaCheck
                        className="w-6 h-6 fill-green-600 cursor-pointer hover:rotate-6 hover:opacity-5"
                        onClick={() => {
                          onClickSaveEdit(item._id, true);
                        }}
                      />
                    ) : (
                      <FaPencilAlt
                        className="w-6 h-6 cursor-pointer hover:rotate-6 hover:opacity-5 fill-black"
                        onClick={() => {
                          onClickEditEvents(item);
                        }}
                      />
                    )}
                  </div>

                  <div className="flex w-4/5">
                    {clickedRowID === item._id ? (
                      <input
                        className="w-full"
                        type="text"
                        name="name"
                        onChange={handleChange}
                        defaultValue={eventAttrb.name}
                      />
                    ) : (
                      <p className="text-left">{item["esemeny"]}</p>
                    )}
                  </div>

                  <div className="flex w-1/2 items-center justify-center">
                    {clickedRowID === item._id ? (
                      <input
                        className="w-full"
                        type="text"
                        name="startDate"
                        onChange={handleChange}
                        defaultValue={eventAttrb.startDate}
                      />
                    ) : (
                      <p className="text-center">{item["kezdo_date"]}</p>
                    )}
                  </div>

                  <div className="flex w-1/2 items-center justify-center">
                    {clickedRowID === item._id ? (
                      <input
                        className="w-full"
                        type="text"
                        name="endDate"
                        onChange={handleChange}
                        defaultValue={eventAttrb.endDate}
                      />
                    ) : (
                      <p className="text-center">{item["vege_date"]}</p>
                    )}
                  </div>
                </div>
              ))}
          </div>
          {/* ESEMENYEK KIVITEL TABLE */}
        </div>
      </div>
    );
  }
}
