export const API_URL = !!+process.env.REACT_APP_PROD ? process.env.REACT_APP_API_URL : "http://localhost:5000"

export const SZAKAGAK = [
    "GEODÉTA",
    "ELEKTRONIKUS",
    "TALAJMECH",
    "STATIKUS",
    "GÉPÉSZ",
    "KATASZTRÓFA",
    "SZEGLEMEZES STATIKUS"
];

export const LOGIN_TIMEOUT = 5000;