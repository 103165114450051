import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import LoadingSpinner from '../../shared/loadingSpinner'
import { resetPassword } from '../../shared/services';

export default function ResetPassword() {
  const [loading, setLoading] = useState(false);
  const [pw, setPw] = useState("");
  const [pwAgain, setPwAgain] = useState("");
  const [error, setError] = useState("");

  const navigate = useNavigate();

  async function onClickResetPW(e){
    e.preventDefault()
    setLoading(true)

    if (pwAgain !== pw) {
        setError("A két jelszó nem egyezik meg!")
        setLoading(false)

        return
    }

    setError("")
    await resetPassword({password:pwAgain})
    .then(()=>{
        setLoading(false)
        toast.success("Jelszó sikeren megváltoztattva!")
        navigate("/")
    })
    .catch((err)=>{
        setLoading(false)
        toast.error("Hiba: " +err)
    })

  }


  return (
    <div>
        <section class="bg-gray-900">
            <div class="flex flex-col items-center justify-center px-6 py-8 mx-auto md:h-screen lg:py-0">

                <div class="w-full p-6 rounded-lg shadow dark:border md:mt-0 sm:max-w-md bg-gray-800 border-gray-700 sm:p-8">
                    <h2 class="mb-1 text-xl font-bold leading-tight tracking-tight md:text-2xl text-white">
                        Új jelszó beállítása
                    </h2>

                    <label className='text-xl font-bold text-red-500'>
                        {error}
                    </label>

                    <form class="mt-4 space-y-4 lg:mt-5 md:space-y-5" action="#">
                        <div>
                            <label for="password" class="block mb-2 text-sm font-medium text-white">Új jelszó</label>
                            <input
                                onChange={(e)=>{setPw(e.target.value)}}
                                type="password"                                
                                placeholder="••••••••" 
                                class=" sm:text-sm rounded-lg focus:ring-primary-600 block w-full p-2.5 bg-gray-700 border-gray-600 placeholder-gray-400 text-white focus:ring-blue-500 focus:border-blue-500"
                            />
                        </div>

                        <div>
                            <label for="confirm-password" class="block mb-2 text-sm font-medium text-white">Jelszó megerősítése</label>
                            <input 
                                onChange={(e)=>{setPwAgain(e.target.value)}}
                                type="password"                                
                                placeholder="••••••••" 
                                class=" sm:text-sm rounded-lg focus:ring-primary-600 block w-full p-2.5 bg-gray-700 border-gray-600 placeholder-gray-400 text-white focus:ring-blue-500 focus:border-blue-500"
                            />
                        </div>

                        <button
                        disabled={loading}
                        onClick={(e)=>onClickResetPW(e)}
                        className="w-full px-4 py-2 tracking-wide text-white transition-colors duration-200 transform bg-orange-600 rounded-md hover:bg-orange-700 focus:outline-none focus:bg-orange-700"
                        >
                        {!loading ? "Mentés" : <LoadingSpinner/>}
                        </button>                    
                    </form>
                </div>
            </div>
            </section>
    </div>
  )
}
