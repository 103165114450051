export default function SearchBarAddressBook({setFilteredData, adressBookData}){
    
    const handleSearch = (event) =>{
        let value = event.target.value.toLowerCase();
        let result = [];
        result = adressBookData.filter((data) => {
            return data["név"].toLowerCase().search(value) !== -1;
        });
        setFilteredData(result)
    }

    return(
        <div className="w-1/4 m-4">
            <form>   
            <div className="relative">
                <div className="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
                    <svg aria-hidden="true" className="w-5 h-5 text-gray-500 dark:text-gray-400" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"></path></svg>
                </div>
                <input 
                onChange={(event) =>handleSearch(event)}
                className="block w-full text-md p-2 pl-10 text-gray-900 border border-gray-300 rounded-lg bg-gray-50 focus:ring-blue-500 focus:border-blue-500" placeholder="Keresés"/>
            </div>
            </form>
        </div>
    )
}