import React, { useState } from 'react'
import { useQuery } from 'react-query'
import { toast } from 'react-toastify'
import DropDown from '../../../shared/DropDown'
import LoadingSpinner from '../../../shared/loadingSpinner'
import { addNewColumn, getAllColumns } from '../../../shared/services'
import RenderColumns from './RenderColumns'

export default function RenderColumnSettings() {

  const [newColClicked,setNewColClicked] = useState(false)
  const [typeSelector,setTypeSelector] = useState(null)
  const [itemType,setItemType] = useState(null)
  const [colName,setColName] = useState("")

  const { isFetching: loadingColumns,data: columns, refetch:refetchColumns  } = useQuery(
    "columns",
    getAllColumns
  );

  async function onClickAddNewCol({col_name, type}){
    if (newColClicked) {
      await addNewColumn({col_name:col_name, type:type})
      .then(() => {
        toast.success("Elmentve!");
      })
      .catch((err) => {
        toast.error("Hiba!\n" + err);
      });

      setNewColClicked(false)
      setColName("")
      refetchColumns()
    } 
    else {
      setNewColClicked(true)
    }
  }

  return (
    <div className="flex flex-col w-full rounded-md shadow-lg bg-white m-4">
    <div className="flex items-center p-2 h-14 w-full border-b-2">
        <h1 className="font-['Segoe UI'] font-bold text-lg">Oszlopok</h1>
    </div>

    <div className='flex flex-row gap-4'>
        <div className='flex mx-5 my-5 gap-4'>
          <button disabled={loadingColumns} type='button' onClick={()=>{onClickAddNewCol({col_name:colName, type: itemType})}} className={newColClicked ? "bg-green-600" : ""}>
            {newColClicked ? "Hozzáadás" : "Új oszlop hozzáadása"}
          </button>

          {newColClicked ?
            <button type='button' onClick={()=>{setNewColClicked(false)}} className="bg-red-600">
            Mégse
          </button>  
          :
          null
          }
        </div>

        {newColClicked ? 
        <div className='flex mx-5 my-5 gap-4'>
          <input
          className="w-[70%]"
          placeholder="Új oszlop neve"
          onChange={(e) => setColName(e.target.value)}
          type="text"
        />

        <DropDown
            dropDownSize={"w-[10%]"}
            array={["Szöveg", "Dátum", "Szakág", "Jelölőnégyzet"]}
            placeholder={"Kiválasztás"}
            state={typeSelector}
            setState={setTypeSelector}
            item={itemType}
            setItem={setItemType}
          />
        </div>
        :
        null
        }
      </div>
      
      <div className='my-5'>

          {!loadingColumns ?
          <RenderColumns columns={columns} refetchColumns={refetchColumns}/>
          : 
          <LoadingSpinner/>
          }
        
      </div>

    </div>
  )
}
