import React from "react";
import HitsBarChart from "./HitsBarChart";
import MapProjects from "./MapProjects";
import Finance from "./Finance";
import Events from "./Events";
import { getProjectStats } from "../../shared/services";
import { useQuery } from "react-query";

const DASHBOARD_COLORS=["bg-blue-500", "bg-[#cce6ff]", "bg-yellow-500", "bg-green-500"]

export default function Dashboard(){
    const USER_ROLE = localStorage.getItem('role')

	const { data } = useQuery('project_stats', getProjectStats)

	return(
	<div className="p-4 h-screen bg-neutral-100 overflow-y-scroll">
		
		<MapProjects/>
		 
		<div className="flex flex-col w-full h-2/5 rounded-md shadow-sm bg-white my-4">
			
			<div className="flex items-center p-2 h-14 w-full border-b-2">
				<h1 className="font-['Segoe UI'] font-bold text-lg">Projekt statisztikák</h1>
			</div>

			{data ?
			<div className="flex flex-row justify-evenly items-center h-full">
			{data.map((item, index)=>{
				return(
				<div key={index} className="flex flex-col justify-center items-center">
				<span className="font-['Segoe UI'] text-xl text-center">
					{item.title}
				</span>
					<div className="pt-4">
						<div className={`flex ${DASHBOARD_COLORS[index]} shadow-xl rounded-full w-20 h-20 items-center justify-center`}>
							<h1 className="font-['Segoe UI'] text-3xl font-bold">{item.value}</h1>
						</div>
					</div>
				</div>
				)
			})}
			</div>
			: null}
		</div>


		{["MANAGER","ADMIN"].includes(USER_ROLE) ? <Finance/> : null}
		
		<div className="flex flex-row justify-evenly h-1/2  mb-20">
			<HitsBarChart/>

			<div className="w-20"/>
			
			<Events/>
		</div>
	</div>

    )
}
